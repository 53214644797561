import React, { useEffect, useState } from 'react';
import Header from '../layout/header.jsx';
import Aside from '../layout/aside.jsx';
import { useParams } from "react-router-dom";
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import NumberCard from '../fragments/numberCard.jsx';
import Filtre from '../fragments/filtre.jsx';
import FormAddIndicateur from './formAddIndicateur.jsx';
import FormDeleteIndicateur from './formDeleteIndicateur.jsx';
import { BACK_API_URL } from '../../constant.js';

function RapportGraphes ({match}){
    const params = useParams();
    const rapportId = params.id;
    const [filtres, setFiltres] = useState({
      zone: 0,
      coop: 0,
      variete: 0,
      sexe: 0,
    });
    const token = localStorage.getItem('token');
    const [rapportIndicateurs,setRapportsIndicateurs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [indicateurs, setIndicateurs] = useState([]);
    
    const [openAddIndicateurForm, setOpenAddIndicateurForm] = useState(false);

    const handleAddIndicateurOpen = () => setOpenAddIndicateurForm(!openAddIndicateurForm);

    const [openDeleteIndicateurForm, setOpenDeleteIndicateurForm]=useState(false);
    const handleDeleteIndicateurOpen = () => {setOpenDeleteIndicateurForm(!openDeleteIndicateurForm)};

    const handleFilterChange = (filterName, filterValue) => {
      setFiltres((prevFilters) => ({
        ...prevFilters,
        [filterName]: filterValue,
      }));
      };
   
    const dynamicColors = (grapheLabels) => {
      const colors = [];
      console.log(grapheLabels);
      if (grapheLabels && grapheLabels.length) {
        for (let i = 0; i < grapheLabels.length; i++) {
          const r = Math.floor(Math.random() * 255);
          const g = Math.floor(Math.random() * 255);
          const b = Math.floor(Math.random() * 255);
          colors.push(`rgb(${r},${g},${b})`);
        }
      }
      return colors;
    };

    const chartComponent = (nomGraphe,typeGraphe,grapheData) => {
      console.log(nomGraphe,typeGraphe,grapheData);
      if (!grapheData) {
        // Handle the case when grapheData is undefined
        return <div>Error: Data not available</div>;
      }
      switch (typeGraphe) {
        case 'pie':
          const pieChartData = {
            labels: grapheData.data.labels || [],
            datasets: [
              {
                label: nomGraphe,
                backgroundColor: dynamicColors(grapheData.labels),
                hoverBackgroundColor: dynamicColors(grapheData.labels),
                data: grapheData.data.data || []
              }
            ]
          }
          return  <div className="d-flex flex-center me-5 pt-2">
                    <Pie data={pieChartData} options={{ maintainAspectRatio: false }} />
                  </div>;
        case 'donught':
          const donughtChartData = {
            labels: grapheData.data.labels || [],
            datasets: [
              {
                label: nomGraphe,
                backgroundColor: dynamicColors(),
                hoverBackgroundColor: dynamicColors(),
                data: grapheData.data.data || []
              }
            ]
          }
          return  <Doughnut data={donughtChartData} options={{ maintainAspectRatio: false }} />
                 
        case 'bar':
          return <Bar options={{ maintainAspectRatio: false }}/>

        case 'nombre':
          return <NumberCard label={grapheData.data.labels}  number={grapheData.data.data} />

        default:
          return <div>Contenu par défaut</div>;
      }
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`${BACK_API_URL}/rapport-indicateur/?rapport=${rapportId}`);
          const data = await response.json();
          setRapportsIndicateurs(data);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);

    useEffect(() => {
      const updateIndicateurs = async () => {
        const result = await extrairIndicateurs();
        setIndicateurs(result);
      };
  
      updateIndicateurs();
    }, [rapportIndicateurs]);
  
    const extrairIndicateurs = async () => {
      const indicateurs = [];
    
      for (let i = 0; i < rapportIndicateurs.length; i++) {
        const dic = {};
        dic['nomGraphe'] = rapportIndicateurs[i].indicateur.nom;
        dic['typeGraphe'] = rapportIndicateurs[i].indicateur.typeGraphe.nom;
        const apiUrl = rapportIndicateurs[i].indicateur.endpoint;
        const typeAction = rapportIndicateurs[i].indicateur.typeAction;
    
        try {
          const response = await fetch(`${apiUrl}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
              "zoneId": filtres.zone,
              "groupementId": filtres.coop,
              "inspectionId": "34",
              "produitId": "0",
              "abonneId": "94",
              "agentId": "0",
              "campagneId": "0",
              "typeAction": typeAction
            })
          });
    
          if (!response.ok) {
            throw new Error(`Failed to fetch. Status: ${response.status}`);
          }
    
          const data = await response.json();
          dic['grapheData'] = data;
          console.log('grapheData', data);
    
        } catch (error) {
          console.error('Error fetching data:', error.message);
        }
    
        indicateurs.push(dic);
      }
    
      return indicateurs;
    };
    

    return(
      <div className="d-flex flex-column flex-root app-root">
        <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
            {/*begin::Header*/}
            <Header />
            {/*end::Header*/}
            {/*begin::Wrapper*/}
            <div className="app-wrapper flex-column flex-row-fluid" style={{ paddingTop: '180px' }} id="kt_app_wrapper">
                {/*begin::Wrapper container*/}
                <div className="app-container container-fluid d-flex flex-row-fluid">
                    {/*begin::Sidebar*/}
                    <Aside />
                    {/*end::Sidebar*/}
                    {/*begin::Main*/}
                    <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                        {/*begin::Content wrapper*/}
                        <div className="d-flex flex-column flex-column-fluid">
                            {/*begin::Toolbar*/}
                            <div id="kt_app_toolbar" className="app-toolbar d-flex pb-3 pb-lg-5">
                                {/*begin::Toolbar container*/}
                                <div className="d-flex flex-stack flex-row-fluid">
                                    {/*begin::Toolbar container*/}
                                    <div className="d-flex flex-column flex-row-fluid">
                                        {/*begin::Toolbar wrapper*/}
                                        {/*begin::Page title*/}
                                        <div className="page-title d-flex align-items-center me-3">
                                            {/*begin::Title*/}
                                            <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-lg-2x gap-2">
                                                <span>Pilotage</span>
                                            </h1>
                                            {/*end::Title*/}
                                        </div>
                                        {/*end::Page title*/}
                                        {/*begin::Breadcrumb*/}
                                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold mb-3 fs-7">
                                            {/*begin::Item*/}
                                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                                <a href="index.html" className="text-white text-hover-primary">
                                                    <i className="ki-outline ki-home text-gray-700 fs-6"></i>
                                                </a>
                                            </li>
                                            {/*end::Item*/}
                                            {/*begin::Item*/}
                                            <li className="breadcrumb-item">
                                                <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
                                            </li>
                                            {/*end::Item*/}
                                            {/*begin::Item*/}
                                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">Dashboards</li>
                                            {/*end::Item*/}
                                            {/*begin::Item*/}
                                            <li className="breadcrumb-item">
                                                <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
                                            </li>
                                            {/*end::Item*/}
                                            {/*begin::Item*/}
                                            <li className="breadcrumb-item text-gray-700">Pilotage</li>
                                            {/*end::Item*/}
                                            {/*begin::Item*/}
                                            <li className="breadcrumb-item">
                                                <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
                                            </li>
                                            {/*end::Item*/}
                                            {/*begin::Item*/}
                                            <li className="breadcrumb-item text-gray-700">Rapport</li>
                                            {/*end::Item*/}
                                        </ul>
                                        {/*end::Breadcrumb*/}
                                    </div>
                                    {/*end::Toolbar container*/}
                                </div>
                                {/*end::Toolbar container*/}
                            </div>
                            {/*end::Toolbar*/}
                            {/*begin::Content*/}
                            <div id="kt_app_content" className="app-content flex-column-fluid">
                                {/*begin::Card*/}
                                <div className="card card-flush">
                                  {/*begin::Card header*/}
                                  <div className="card-header pt-8">
                                    <div className="card-title">
                                      {/*begin::Search*/}
                                      <div className="d-flex align-items-center position-relative my-1">
                                        <i className="ki-outline ki-magnifier fs-1 position-absolute ms-6" />
                                        <input
                                          type="text"
                                          data-kt-filemanager-table-filter="search"
                                          className="form-control form-control-solid w-250px ps-15"
                                          placeholder="Recherche rapport"
                                        />
                                      </div>
                                      {/*end::Search*/}
                                    </div>
                                    {/*begin::Card toolbar*/}
                                    <div className="card-toolbar">
                                      {/*begin::Toolbar*/}
                                      <div
                                        className="d-flex justify-content-end"
                                        data-kt-filemanager-table-toolbar="base"
                                      >
                                        {/*begin::Export*/}
                                        <button
                                          type="button"
                                          className="btn btn-flex btn-light-primary me-3"
                                          id="kt_file_manager_new_folder"
                                          onClick={handleAddIndicateurOpen}
                                        >
                                          <i className="ki-outline ki-plus-square fs-2" />
                                          Ajouter indicateur
                                        </button>
                                        {/*end::Export*/}
                                        {/*begin::Add customer*/}
                                        <button
                                          type="button"
                                          className="btn btn-flex btn-primary"
                                          data-bs-toggle="modal"
                                          data-bs-target="#kt_modal_upload"
                                          onClick={handleDeleteIndicateurOpen}
                                        >
                                          Retirer indicateur
                                        </button>
                                        {/*end::Add customer*/}
                                      </div>
                                      {/*end::Toolbar*/}
                                      
                                    </div>
                                    {/*end::Card toolbar*/}
                                  </div>
                                  {/*end::Card header*/}
                                  {/*begin::Card body*/}
                                  <div className="card-body">
                                    <div className='row g-5 g-xl-10 mb-xl-10'>
                                      
                                        {loading ? (
                                            <div>Loading...</div>
                                          ) : (
                                            
                                            indicateurs.map((indicateur, index) => (								
								                              <div className="col-xxl-6">
                                                <div className="card card-flush h-lg-100">
                                                  {/*begin::Header*/}
                                                  <div className="card-header pt-7 mb-7">
                                                    <h3 class="card-title align-items-start flex-column">
                                                      <span class="card-label fw-bold text-gray-800">{indicateur.nomGraphe}</span>  
                                                    </h3>
                                                  </div>
                                                  {/*end::Header*/}
                                                  {/*begin::Card body*/}
                                                  <div className="card-body d-flex align-items-end pt-0">
                                                    <div className="w-100 h-200px">
                                                    
                                                    {chartComponent(indicateur.nomGraphe, indicateur.typeGraphe, indicateur.grapheData)}
                                                    
                                                    </div>
                                                  </div>
                                                  {/*end::Card body*/}
                                                </div>
                                              </div>
                                            ))
                                            
                                          )}
                                      
                                    </div>
                                    
                                  </div>
                                  {/*end::Card body*/}
                                </div>
                                {/*end::Card*/}
                            </div>
                            {/*end::Content*/}
                        </div>
                        {/*end::Content wrapper*/}
                    </div>
                    {/*end::Main*/}
                </div>
                {/*end::Wrapper container*/}
            </div>
            {/*end::Wrapper*/}
        </div>
      </div>

    );
}
export default RapportGraphes;
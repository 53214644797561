import { useEffect, useState } from "react";
import { NotificationContainer,  NotificationManager} from 'react-notifications';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form,FormGroup, Label , Input } from 'reactstrap';
import { BACK_API_URL } from '../../constant.js';
function FormAddRapport({args,modal,toggle}){
    const [indicateurs,setIndicateurs]=useState(null);
    const [nom,setNom]=useState('');
    const [indicateurList,setIndicateurList]=useState([]);
    useEffect(()=>{
        fetch(`${BACK_API_URL}/indicateurs/`)
        .then(res => res.json())
        .then(data => setIndicateurs(data))
    },[])
    const handleNomChange = (e) => {
      setNom(e.target.value);
    };
    const handleIndicateurChange = (e) => {
        const selectedIndicateurs = Array.from(e.target.selectedOptions, option => option.value);
        setIndicateurList(selectedIndicateurs);
        console.log(selectedIndicateurs);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const formData = {
          nom: nom,
          indicateurs: indicateurList,
        };
        
        try {
          const response = await fetch(`${BACK_API_URL}/enregistrer-rapport/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (response.ok) {
            console.log('Rapport enregistré avec succès');
            NotificationManager.success('Rapport enregistré avec succès');
            
          } else {         
            NotificationManager.warning('Erreur lors de l\'enregistrement du rapport', 3000);
            
            // Reset form fields if needed
            setNom('');
            setIndicateurList([]);
          }
        } catch (error) {
          console.error('Erreur réseau:', error);
          alert('Erreur réseau');
        }
      };

    return(
      <div>
     
        <Modal isOpen={modal} toggle={toggle} {...args}>
          <ModalHeader toggle={toggle}> Ajouter Rapport 
          
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="exampleEmail">
                  Nom rapport
                </Label>
                <Input
                  id="exampleEmail"
                  name="nom"
                  value={nom}
                  onChange={handleNomChange}
                  placeholder="nom rapport"
                  type="email"
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleSelectMulti">
                  choisir indicateurs
                </Label>
                <Input
                  id="exampleSelectMulti"
                  multiple
                  name="indicateurs"
                  value={indicateurList}
                  onChange={handleIndicateurChange}
                  type="select"
                >
                            {/* <option value="0">choisir Indicateurs</option> */}
                            {indicateurs && indicateurs.map((indicateur) => {
                            return (
                                <option key={indicateur.id} value={indicateur.id}>{indicateur.nom}</option>
                                            )
                            })}  
                </Input>
                </FormGroup>
              </Form>
          </ModalBody>
          <ModalFooter>
          <NotificationContainer/>
            <button type="submit" onClick={handleSubmit} id="kt_modal_add_customer_submit" className="btn btn-primary">
            <span className="indicator-label" >Ajouter</span>
                
            </button>
                      
            
          </ModalFooter>
        </Modal>
    </div>
        
        
    );

}
export default FormAddRapport;
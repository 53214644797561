const List = ({items,itemsAttributs}) => {
    console.log('items',items,itemsAttributs);
    return (
        <div className="card card-xl-stretch mb-5 mb-xl-8">
            {/*begin::Body*/}
            <div className="card-body py-3">
                {/*begin::Table container*/}
                <div className="table-responsive">
                {/*begin::Table*/}
                <table className="table align-middle gs-0 gy-5">
                    {/*begin::Table head*/}
                    <thead>
                    <tr>
                        <th className="p-0 min-w-150px" />
                        <th className="p-0 min-w-150px" />
                        <th className="p-0 min-w-150px" />
                    </tr>
                    </thead>
                    {/*end::Table head*/}
                    {/*begin::Table body*/}
                    <tbody>
                    {items.map((item)=>(<>
                        <tr> 
                            {itemsAttributs.map((itemAttribut)=>(
                                <td key={itemAttribut} className="text-gray-800 fw-bold text-hover-primary fs-6">
                                    {item[itemAttribut]?item[itemAttribut]:'item'}
                                </td>
                            ))}
                            
                        </tr>
                        
                    </>))}
                    </tbody>
                    {/*end::Table body*/}
                </table>
                {/*end::Table*/}
                </div>
                {/*end::Table container*/}
            </div>
            {/*end::Body*/}
        </div>

    
    )
}
export default List
function NumberCard ({label,number}){
	console.log(label,number)
    return (
		<div className="col-xxl-6">
		{/*begin::card */}
		<div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-center border-0 h-md-50 mb-5 mb-xl-10" style={{backgroundColor: '#080655'}}>
			{/*begin::Header*/}
			<div className="card-header pt-5">
				{/*begin::Title*/}
				<div className="card-title d-flex flex-column">
				{/*begin::Amount*/}
				<span className="fs-2hx fw-bold text-white me-2 lh-1 ls-n2">{number}</span>
				{/*end::Amount*/}
				{/*begin::Subtitle*/}
				<span className="text-white opacity-50 pt-1 fw-semibold fs-6">{label}</span>
				{/*end::Subtitle*/}
				</div>
				{/*end::Title*/}
			</div>
			{/*end::Header*/}
			{/*begin::Card body*/}
			<div className="card-body d-flex align-items-end pt-0">
				
			</div>
			{/*end::Card body*/}
		</div>
		{/*end::Card */}
		
	</div>
    )

}
export default NumberCard;
import React, { useEffect, useState } from 'react';
import Filtre from '../fragments/filtre.jsx'
import {Doughnut} from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { GSMILES_API_URL, BODY } from '../../constant.js';
import Footer from '../layout/footer.jsx';

Chart.register(/* ... */);
function Content() {
	
	const [loading, setLoading] = useState(false)
	const [error,setError]=useState('')
	const [filtres, setFiltres] = useState({
		zone: 0,
		coop: 0,
		variete: 0,
		sexe: 0,
	});

	
	const [numberProducteur, setNumberProducteur] = useState(null);
	const [surfaceTotale, setSurfaceTotale] = useState(null);
	const [nombreParcelle, setNombreParcelle] = useState(null);
	const [numberProducteurFemme, setNumberProducteurFemme] = useState(null);
	const [numberProducteurHomme, setNumberProducteurHomme] = useState(null);
	const [surfaceTotaleFemme, setSurfaceTotaleFemme] = useState(null);
	const [surfaceTotaleHomme, setSurfaceTotaleHomme] = useState(null);
	const [nombreParcelleFemme, setNombreParcelleFemme] = useState(null);
	const [nombreParcelleHomme, setNombreParcelleHomme] = useState(null);
	const token = localStorage.getItem('token');
	useEffect(() => {
		const fetchProducteurStatsData = async () => {
			try {
				const response = await fetch(`${GSMILES_API_URL}/api/traite/stats/producteur`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
					body:JSON.stringify({
						"zoneId": filtres.zone,
						"groupementId": filtres.coop,
						"inspectionId": "34",
						"produitId": "0",
						"abonneId": "94",
						"agentId": "0",
						"campagneId": "0",
						"typeAction":"1"
					  })
				});
	
				if (!response.ok) {
					throw new Error(`Failed to fetch. Status: ${response.status}`);
				}
				const data = await response.json();
				console.log('data',data);
				setNumberProducteur(data.data.nbreTotal[0].nbreTotal);
				setNumberProducteurHomme(data.data.nbreTotalFemme[0].nbreTotal)
				setNumberProducteurFemme(data.data.nbreTotalHomme[0].nbreTotal)
				
			} catch (error) {
				console.error('Error fetching data:', error.message);
			}
		};
	
		fetchProducteurStatsData();
	}, [filtres]);
	// useEffect parcell
	useEffect(() => {
			const fetchParcelleStatsData = async () => {
				try {
					const response = await fetch(`${GSMILES_API_URL}/api/traite/stats/get/info/parcelle`, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${token}`,
						},
						body:JSON.stringify({
							"zoneId": filtres.zone,
							"groupementId": filtres.coop,
							"inspectionId": "34",
							"produitId": "0",
							"abonneId": "94",
							"agentId": "0",
							"campagneId": "0",
							"typeAction":"2"
						  })
					});

					if (!response.ok) {
						throw new Error(`Failed to fetch. Status: ${response.status}`);
					}
					const data = await response.json();
					console.log('data2',data);
					setNombreParcelle(data.data.listeParcelle[0].countSurface);
					setSurfaceTotale(data.data.listeParcelle[0].valSurface.toFixed(2))
					
				} catch (error) {
					console.error('Error fetching data:', error.message);
				}
			};

			fetchParcelleStatsData();
		}, [filtres]

	)
	useEffect(() => {
		const fetchParcelleStatsSexeData = async () => {
			try {
				const response = await fetch(`${GSMILES_API_URL}/api/traite/stats/producteur/detail/sexe`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
					body:JSON.stringify({
						"zoneId": filtres.zone,
						"groupementId": filtres.coop,
						"inspectionId": "34",
						"produitId": "0",
						"abonneId": "94",
						"agentId": "0",
						"campagneId": "0",
						"typeAction":"2"
					  })
				});

				if (!response.ok) {
					throw new Error(`Failed to fetch. Status: ${response.status}`);
				}
				const data = await response.json();
				console.log('data2',data);
				setNombreParcelleFemme(data.data.data[1]);
				setNombreParcelleHomme(data.data.data[0])
				
			} catch (error) {
				console.error('Error fetching data:', error.message);
			}
		};

		fetchParcelleStatsSexeData();
	}, [filtres]

	)

	useEffect(() => {
		const fetchSurfaceStatsSexeData = async () => {
			try {
				const response = await fetch(`${GSMILES_API_URL}/api/traite/stats/producteur/detail/sexe`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
					body:JSON.stringify({
						"zoneId": filtres.zone,
						"groupementId": filtres.coop,
						"inspectionId": "34",
						"produitId": "0",
						"abonneId": "94",
						"agentId": "0",
						"campagneId": "0",
						"typeAction":"3"
					  })
				});

				if (!response.ok) {
					throw new Error(`Failed to fetch. Status: ${response.status}`);
				}
				const data = await response.json();
				console.log('data2',data);
				setSurfaceTotaleFemme(data.data.data[1]);
				setSurfaceTotaleHomme(data.data.data[0])
				
			} catch (error) {
				console.error('Error fetching data:', error.message);
			}
		};

		fetchSurfaceStatsSexeData();
	}, [filtres]

	)


	const donughtParcelleData = {
		labels: ['Homme', 'Femme'],
		datasets: [
		  {
			label: 'Parcelle',
			backgroundColor: [
			  '#2FDE00',
			  '#00A6B4',
			],
			hoverBackgroundColor: [
			'#175000',
			'#003350',
			],
			data: [nombreParcelleHomme,nombreParcelleFemme]
		  }
		]
	  }
	const donughtSurfaceData = {
	labels: ['Homme', 'Femme'],
	datasets: [
		{
		label: 'Surface',
		backgroundColor: [
			'#2FDE00',
			'#00A6B4',
		],
		hoverBackgroundColor: [ 
		'#175000',
		'#003350',
		],
		data: [surfaceTotaleHomme,surfaceTotaleFemme]
		}
	]
	}
	const donughtProducteurData = {
		labels: ['Homme', 'Femme'],
		datasets: [
		  {
			label: 'producteur',
			backgroundColor: [
			  '#2FDE00',
			  '#00A6B4',
			],
			hoverBackgroundColor: [
			'#175000',
			'#003350',
			],
			data: [numberProducteurHomme,numberProducteurFemme]
		  }
		]
	  }

	  
	const handleFilterChange = (filterName, filterValue) => {
		setFiltres((prevFilters) => ({
		  ...prevFilters,
		  [filterName]: filterValue,
		}));
	  };
	
    return(
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
			{/*begin::Content wrapper*/}
			<div className="d-flex flex-column flex-column-fluid">
				{/*begin::Toolbar*/}
				<div id="kt_app_toolbar" className="app-toolbar d-flex pb-3 pb-lg-5">
					{/*begin::Toolbar container*/}
					<div className="d-flex flex-stack flex-row-fluid">
						{/*begin::Toolbar container*/}
						<div className="d-flex flex-column flex-row-fluid">
							{/*begin::Toolbar wrapper*/}
							{/*begin::Page title*/}
							<div className="page-title d-flex align-items-center me-3">
								{/*begin::Title*/}
								<h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-lg-2x gap-2">
									<span>Accueil</span>
								</h1>
								{/*end::Title*/}
							</div>
							{/*end::Page title*/}
							{/*begin::Breadcrumb*/}
							<ul className="breadcrumb breadcrumb-separatorless fw-semibold mb-3 fs-7">
								{/*begin::Item*/}
								<li className="breadcrumb-item text-gray-700 fw-bold lh-1">
									<a href="index.html" className="text-white text-hover-primary">
										<i className="ki-outline ki-home text-gray-700 fs-6"></i>
									</a>
								</li>
								{/*end::Item*/}
								{/*begin::Item*/}
								<li className="breadcrumb-item">
									<i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
								</li>
								{/*end::Item*/}
								{/*begin::Item*/}
								<li className="breadcrumb-item text-gray-700 fw-bold lh-1">Accueil</li>
								{/*end::Item*/}
								{/*begin::Item*/}
								<li className="breadcrumb-item">
									<i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
								</li>
								{/*end::Item*/}
								
							</ul>
							{/*end::Breadcrumb*/}
						</div>
						{/*end::Toolbar container*/}
						
					</div>
					{/*end::Toolbar container*/}
				</div>
				{/*end::Toolbar*/}
				{/*begin::Content*/}
				<div id="kt_app_content" className="app-content flex-column-fluid">
					{/*begin::Row*/}
					<div className="row g-5 g-xl-10 mb-xl-10">	
						<div className='col'>						
							<div className="d-flex align-self-center flex-center flex-shrink-0">
								< Filtre onFilterChange={handleFilterChange} />
							</div>	
						</div>											
					</div>
					<div className="row g-5 g-xl-10 mb-xl-10">
						<div className='col'>
							<div className='row g-5 g-xl-10 mb-xl-10'>																				
								{/*begin::Col */}									
								<div className="col-xxl-6">
									{/*begin::card */}
									<div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-center border-0 h-md-50 mb-5 mb-xl-10" style={{backgroundColor: '#080655'}}>
										{/*begin::Header*/}
										<div className="card-header pt-5">
											{/*begin::Title*/}
											<div className="card-title d-flex flex-column">
											{/*begin::Amount*/}
											<span className="fs-2hx fw-bold text-white me-2 lh-1 ls-n2">69</span>
											{/*end::Amount*/}
											{/*begin::Subtitle*/}
											<span className="text-white opacity-50 pt-1 fw-semibold fs-6">Active Projects</span>
											{/*end::Subtitle*/}
											</div>
											{/*end::Title*/}
										</div>
										{/*end::Header*/}
										{/*begin::Card body*/}
										<div className="card-body d-flex align-items-end pt-0">
											{/*begin::Progress*/}
											<div className="d-flex align-items-center flex-column mt-3 w-100">
											<div className="d-flex justify-content-between fw-bold fs-6 text-white opacity-50 w-100 mt-auto mb-2">
												<span>43 Pending</span>
												<span>72%</span>
											</div>
											<div className="h-8px mx-3 w-100 bg-light-danger rounded">
												<div className="bg-danger rounded h-8px" role="progressbar" style={{width: '72%'}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
											</div>
											</div>
											{/*end::Progress*/}
										</div>
										{/*end::Card body*/}
									</div>
									{/*end::Card */}
									{/*begin::nbre producteur*/}
									<div className="card card-flush h-md-50 mb-5 mb-xl-10">
										{/*begin::Header*/}
										<div className="card-header pt-5">
											{/*begin::Title*/}
											<div className="card-title d-flex flex-column">
												{/*begin::Info*/}
												<div className="d-flex align-items-center">
													{/*begin::Currency*/}
													{/* <span className="fs-4 fw-semibold text-gray-500 me-1 align-self-start">$</span> */}
													{/*end::Currency*/}
													{/*begin::Amount*/}
													<span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{numberProducteur}</span>
													{/*end::Amount*/}
													{/*begin::Badge*/}
													{/* <span className="badge badge-light-success fs-base">
													<i className="ki-outline ki-arrow-up fs-5 text-success ms-n1"></i>2.2%</span> */}
													{/*end::Badge*/}
												</div>
												{/*end::Info*/}
												{/*begin::Subtitle*/}
												<span className="text-gray-500 pt-1 fw-semibold fs-6">Nombre de producteur</span>
												{/*end::Subtitle*/}
											</div>
											{/*end::Title*/}
										</div>
										{/*end::Header*/}
										{/*begin::Card body*/}
										<div className="card-body pt-2 pb-4 d-flex flex-wrap align-items-center">
											{/*begin::Chart*/}
											<div className="d-flex flex-center me-5 pt-2">
												<div id="kt_card_widget_17_chart" style={{minWidth: "1px", minHeight: "1px" }} data-kt-size="70" data-kt-line="11">
												<Doughnut
													data={donughtProducteurData}
													height={50}
													width={50}
													options={{ maintainAspectRatio: false,
														plugins: {
															legend: {
															display: false, // Cette ligne masque la légende
															},
														}
													}}
													
												/>
												</div>
												<div className="d-flex flex-column content-justify-center flex-row-fluid">
												{/*begin::Label*/}
												<div className="d-flex fw-semibold align-items-center">
													{/*begin::Bullet*/}
													<div className="bullet w-8px h-3px rounded-2 bg-success me-3"></div>
													{/*end::Bullet*/}
													{/*begin::Label*/}
													<div className="text-gray-500 flex-grow-1 me-4">Homme</div>
													{/*end::Label*/}
													{/*begin::Stats*/}
													<div className="fw-bolder text-gray-700 text-xxl-end">{numberProducteurHomme}</div>
													{/*end::Stats*/}
												</div>
												{/*end::Label*/}
												{/*begin::Label*/}
												<div className="d-flex fw-semibold align-items-center my-3">
													{/*begin::Bullet*/}
													<div className="bullet w-8px h-3px rounded-2 bg-primary me-3"></div>
													{/*end::Bullet*/}
													{/*begin::Label*/}
													<div className="text-gray-500 flex-grow-1 me-4">Femme</div>
													{/*end::Label*/}
													{/*begin::Stats*/}
													<div className="fw-bolder text-gray-700 text-xxl-end">{numberProducteurFemme}</div>
													{/*end::Stats*/}
												</div>
												{/*end::Label*/}
												
											</div>
											</div>
											{/*end::Chart*/}
											{/*begin::Labels*/}
											
											{/*end::Labels*/}
										</div>
										{/*end::Card body*/}
									</div>
									{/*end::nbre producteur*/}
								</div>
								{/*end::Col*/}
								{/*begin::Col */}
								<div className="col-xxl-6">
									{/*begin::surface*/}
									<div className="card card-flush h-md-50 mb-5 mb-xl-10">
										{/*begin::Header*/}
										<div className="card-header pt-5">
											{/*begin::Title*/}
											<div className="card-title d-flex flex-column">
												{/*begin::Info*/}
												<div className="d-flex align-items-center">
													{/*begin::Currency*/}
													{/* <span className="fs-4 fw-semibold text-gray-500 me-1 align-self-start">$</span> */}
													{/*end::Currency*/}
													{/*begin::Amount*/}
													<span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{surfaceTotale}</span>
													{/*end::Amount*/}
													{/*begin::Badge*/}
													{/* <span className="badge badge-light-success fs-base">
													<i className="ki-outline ki-arrow-up fs-5 text-success ms-n1"></i>2.2%</span> */}
													{/*end::Badge*/}
												</div>
												{/*end::Info*/}
												{/*begin::Subtitle*/}
												<span className="text-gray-500 pt-1 fw-semibold fs-6">Surface Totale</span>
												{/*end::Subtitle*/}
											</div>
											{/*end::Title*/}
										</div>
										{/*end::Header*/}
										{/*begin::Card body*/}
										<div className="card-body pt-2 pb-4 d-flex flex-wrap align-items-center">
											{/*begin::Chart*/}
											<div className="d-flex flex-center me-5 pt-2">
												<div id="kt_card_widget_17_chart" style={{minWidth: "1px", minHeight: "1px" }} data-kt-size="70" data-kt-line="11">
												<Doughnut
													data={donughtSurfaceData}
													height={50}
													width={50}
													options={{ maintainAspectRatio: false,
														plugins: {
															legend: {
															display: false, // Cette ligne masque la légende
															},
														}
													}}
													
												/>
												</div>
												<div className="d-flex flex-column content-justify-center flex-row-fluid">
												{/*begin::Label*/}
												<div className="d-flex fw-semibold align-items-center">
													{/*begin::Bullet*/}
													<div className="bullet w-8px h-3px rounded-2 bg-success me-3"></div>
													{/*end::Bullet*/}
													{/*begin::Label*/}
													<div className="text-gray-500 flex-grow-1 me-4">Homme</div>
													{/*end::Label*/}
													{/*begin::Stats*/}
													<div className="fw-bolder text-gray-700 text-xxl-end">{nombreParcelleHomme}</div>
													{/*end::Stats*/}
												</div>
												{/*end::Label*/}
												{/*begin::Label*/}
												<div className="d-flex fw-semibold align-items-center my-3">
													{/*begin::Bullet*/}
													<div className="bullet w-8px h-3px rounded-2 bg-primary me-3"></div>
													{/*end::Bullet*/}
													{/*begin::Label*/}
													<div className="text-gray-500 flex-grow-1 me-4">Femme</div>
													{/*end::Label*/}
													{/*begin::Stats*/}
													<div className="fw-bolder text-gray-700 text-xxl-end">{nombreParcelleFemme}</div>
													{/*end::Stats*/}
												</div>
												{/*end::Label*/}
												
											</div>
											</div>
											{/*end::Chart*/}
											{/*begin::Labels*/}
											
											{/*end::Labels*/}
										</div>
										{/*end::Card body*/}
									</div>
									{/*end::Card */}
									{/*begin::nbre parcelle*/}
									<div className="card card-flush h-md-50 mb-5 mb-xl-10">
										{/*begin::Header*/}
										<div className="card-header pt-5">
											{/*begin::Title*/}
											<div className="card-title d-flex flex-column">
												{/*begin::Info*/}
												<div className="d-flex align-items-center">
													{/*begin::Currency*/}
													{/* <span className="fs-4 fw-semibold text-gray-500 me-1 align-self-start">$</span> */}
													{/*end::Currency*/}
													{/*begin::Amount*/}
													<span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{nombreParcelle}</span>
													{/*end::Amount*/}
													{/*begin::Badge*/}
													{/* <span className="badge badge-light-success fs-base">
													<i className="ki-outline ki-arrow-up fs-5 text-success ms-n1"></i>2.2%</span> */}
													{/*end::Badge*/}
												</div>
												{/*end::Info*/}
												{/*begin::Subtitle*/}
												<span className="text-gray-500 pt-1 fw-semibold fs-6">Nombre de parcelle</span>
												{/*end::Subtitle*/}
											</div>
											{/*end::Title*/}
										</div>
										{/*end::Header*/}
										{/*begin::Card body*/}
										<div className="card-body pt-2 pb-4 d-flex flex-wrap align-items-center">
											{/*begin::Chart*/}
											<div className="d-flex flex-center me-5 pt-2">
												<div id="kt_card_widget_17_chart" style={{minWidth: "1px", minHeight: "1px" }} data-kt-size="70" data-kt-line="11">
												<Doughnut
													data={donughtParcelleData}
													height={50}
													width={50}
													options={{ maintainAspectRatio: false,
														plugins: {
															legend: {
															display: false, // Cette ligne masque la légende
															},
														}
													}}
													
												/>
												</div>
												<div className="d-flex flex-column content-justify-center flex-row-fluid">
												{/*begin::Label*/}
												<div className="d-flex fw-semibold align-items-center">
													{/*begin::Bullet*/}
													<div className="bullet w-8px h-3px rounded-2 bg-success me-3"></div>
													{/*end::Bullet*/}
													{/*begin::Label*/}
													<div className="text-gray-500 flex-grow-1 me-4">Homme</div>
													{/*end::Label*/}
													{/*begin::Stats*/}
													<div className="fw-bolder text-gray-700 text-xxl-end">{nombreParcelleHomme}</div>
													{/*end::Stats*/}
												</div>
												{/*end::Label*/}
												{/*begin::Label*/}
												<div className="d-flex fw-semibold align-items-center my-3">
													{/*begin::Bullet*/}
													<div className="bullet w-8px h-3px rounded-2 bg-primary me-3"></div>
													{/*end::Bullet*/}
													{/*begin::Label*/}
													<div className="text-gray-500 flex-grow-1 me-4">Femme</div>
													{/*end::Label*/}
													{/*begin::Stats*/}
													<div className="fw-bolder text-gray-700 text-xxl-end">{nombreParcelleFemme}</div>
													{/*end::Stats*/}
												</div>
												{/*end::Label*/}
												
											</div>
											</div>
											{/*end::Chart*/}
											{/*begin::Labels*/}
											
											{/*end::Labels*/}
										</div>
										{/*end::Card body*/}
									</div>
									{/*end::nbre parcelle*/}
								</div>
								{/*end::Col*/}
							</div>
						</div>																			
							{/*begin::Col activite*/}
							<div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
								{/*begin::Timeline widget 3*/}
								<div className="card h-md-100">
									{/*begin::Header*/}
									<div className="card-header border-0 pt-5">
										<h3 className="card-title align-items-start flex-column">
											<span className="card-label fw-bold text-gray-900">Activités </span>
											<span className="text-muted mt-1 fw-semibold fs-7">Dernières activités</span>
										</h3>
										{/*begin::Toolbar*/}
										<div className="card-toolbar">
											<a href="#" className="btn btn-sm btn-light">Toutes les activités</a>
										</div>
										{/*end::Toolbar*/}
									</div>
									{/*end::Header*/}
									
									{/*begin::Body*/}
									<div className="card-body pt-7 px-0">
										{/*begin::Nav*/}
										<ul className="nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex justify-content-between mb-8 px-5">
											{/*begin::Nav item*/}
											<li className="nav-item p-0 ms-0">
												{/*begin::Date*/}
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger" data-bs-toggle="tab" href="#kt_timeline_widget_3_tab_content_1">
													<span className="fs-7 fw-semibold">Fr</span>
													<span className="fs-6 fw-bold">20</span>
												</a>
												{/*end::Date*/}
											</li>
											{/*end::Nav item*/}
											{/*begin::Nav item*/}
											<li className="nav-item p-0 ms-0">
												{/*begin::Date*/}
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger" data-bs-toggle="tab" href="#kt_timeline_widget_3_tab_content_2">
													<span className="fs-7 fw-semibold">Sa</span>
													<span className="fs-6 fw-bold">21</span>
												</a>
												{/*end::Date*/}
											</li>
											{/*end::Nav item*/}
											{/*begin::Nav item*/}
											<li className="nav-item p-0 ms-0">
												{/*begin::Date*/}
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger" data-bs-toggle="tab" href="#kt_timeline_widget_3_tab_content_3">
													<span className="fs-7 fw-semibold">Su</span>
													<span className="fs-6 fw-bold">22</span>
												</a>
												{/*end::Date*/}
											</li>
											{/*end::Nav item*/}
											{/*begin::Nav item*/}
											<li className="nav-item p-0 ms-0">
												{/*begin::Date*/}
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger active" data-bs-toggle="tab" href="#kt_timeline_widget_3_tab_content_4">
													<span className="fs-7 fw-semibold">Tu</span>
													<span className="fs-6 fw-bold">23</span>
												</a>
												{/*end::Date*/}
											</li>
											{/*end::Nav item*/}
											{/*begin::Nav item*/}
											<li className="nav-item p-0 ms-0">
												{/*begin::Date*/}
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger" data-bs-toggle="tab" href="#kt_timeline_widget_3_tab_content_5">
													<span className="fs-7 fw-semibold">Tu</span>
													<span className="fs-6 fw-bold">24</span>
												</a>
												{/*end::Date*/}
											</li>
											{/*end::Nav item*/}
											{/*begin::Nav item*/}
											<li className="nav-item p-0 ms-0">
												{/*begin::Date*/}
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger" data-bs-toggle="tab" href="#kt_timeline_widget_3_tab_content_6">
													<span className="fs-7 fw-semibold">We</span>
													<span className="fs-6 fw-bold">25</span>
												</a>
												{/*end::Date*/}
											</li>
											{/*end::Nav item*/}
											{/*begin::Nav item*/}
											<li className="nav-item p-0 ms-0">
												{/*begin::Date*/}
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger" data-bs-toggle="tab" href="#kt_timeline_widget_3_tab_content_7">
													<span className="fs-7 fw-semibold">Th</span>
													<span className="fs-6 fw-bold">26</span>
												</a>
												{/*end::Date*/}
											</li>
											{/*end::Nav item*/}
											{/*begin::Nav item*/}
											<li className="nav-item p-0 ms-0">
												{/*begin::Date*/}
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger" data-bs-toggle="tab" href="#kt_timeline_widget_3_tab_content_8">
													<span className="fs-7 fw-semibold">Fri</span>
													<span className="fs-6 fw-bold">27</span>
												</a>
												{/*end::Date*/}
											</li>
											{/*end::Nav item*/}
											{/*begin::Nav item*/}
											<li className="nav-item p-0 ms-0">
												{/*begin::Date*/}
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger" data-bs-toggle="tab" href="#kt_timeline_widget_3_tab_content_9">
													<span className="fs-7 fw-semibold">Sa</span>
													<span className="fs-6 fw-bold">28</span>
												</a>
												{/*end::Date*/}
											</li>
											{/*end::Nav item*/}
											{/*begin::Nav item*/}
											<li className="nav-item p-0 ms-0">
												{/*begin::Date*/}
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger" data-bs-toggle="tab" href="#kt_timeline_widget_3_tab_content_10">
													<span className="fs-7 fw-semibold">Su</span>
													<span className="fs-6 fw-bold">29</span>
												</a>
												{/*end::Date*/}
											</li>
											{/*end::Nav item*/}
											{/*begin::Nav item*/}
											<li className="nav-item p-0 ms-0">
												{/*begin::Date*/}
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger" data-bs-toggle="tab" href="#kt_timeline_widget_3_tab_content_11">
													<span className="fs-7 fw-semibold">Mo</span>
													<span className="fs-6 fw-bold">30</span>
												</a>
												{/*end::Date*/}
											</li>
											{/*end::Nav item*/}
										</ul>
										{/*end::Nav*/}
										{/*begin::Tab Content (ishlamayabdi)*/}
										<div className="tab-content mb-2 px-9">
											{/*begin::Tap pane*/}
											<div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_1">
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">10:20 - 11:00 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Peter Marcus</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">12:00 - 13:40 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">Dashboard UI/UX Design Review</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Bob</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">16:30 - 17:00 
														<span className="text-gray-500 fw-semibold fs-7">PM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Mark Morris</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
											</div>
											{/*end::Tap pane*/}
											{/*begin::Tap pane*/}
											<div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_2">
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">16:30 - 17:00 
														<span className="text-gray-500 fw-semibold fs-7">PM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Mark Morris</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">12:00 - 13:40 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Peter Marcus</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">10:20 - 11:00 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Peter Marcus</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
											</div>
											{/*end::Tap pane*/}
											{/*begin::Tap pane*/}
											<div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_3">
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-primary"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">10:20 - 11:00 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Peter Marcus</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">12:00 - 13:40 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Bob</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">16:30 - 17:00 
														<span className="text-gray-500 fw-semibold fs-7">PM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Mark Morris</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
											</div>
											{/*end::Tap pane*/}
											{/*begin::Tap pane*/}
											<div className="tab-pane fade show active" id="kt_timeline_widget_3_tab_content_4">
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">10:20 - 11:00 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Peter Marcus</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">16:30 - 17:00 
														<span className="text-gray-500 fw-semibold fs-7">PM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">Dashboard UI/UX Design Review</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Bob</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">12:00 - 13:40 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Mark Morris</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
											</div>
											{/*end::Tap pane*/}
											{/*begin::Tap pane*/}
											<div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_5">
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-danger"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">12:00 - 13:40 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Dashboard UI/UX Design Review</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Bob</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">10:20 - 11:00 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Mark Morris</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">16:30 - 17:00 
														<span className="text-gray-500 fw-semibold fs-7">PM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Peter Marcus</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
											</div>
											{/*end::Tap pane*/}
											{/*begin::Tap pane*/}
											<div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_6">
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">10:20 - 11:00 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Mark Morris</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-primary"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">16:30 - 17:00 
														<span className="text-gray-500 fw-semibold fs-7">PM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Peter Marcus</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">12:00 - 13:40 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Dashboard UI/UX Design Review</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Bob</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
											</div>
											{/*end::Tap pane*/}
											{/*begin::Tap pane*/}
											<div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_7">
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">12:00 - 13:40 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Bob</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-danger"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">10:20 - 11:00 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Dashboard UI/UX Design Review</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Peter Marcus</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">16:30 - 17:00 
														<span className="text-gray-500 fw-semibold fs-7">PM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Mark Morris</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
											</div>
											{/*end::Tap pane*/}
											{/*begin::Tap pane*/}
											<div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_8">
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">16:30 - 17:00 
														<span className="text-gray-500 fw-semibold fs-7">PM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Peter Marcus</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">10:20 - 11:00 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Mark Morris</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-danger"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">12:00 - 13:40 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Dashboard UI/UX Design Review</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Bob</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
											</div>
											{/*end::Tap pane*/}
											{/*begin::Tap pane*/}
											<div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_9">
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">12:00 - 13:40 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Bob</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-primary"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">16:30 - 17:00 
														<span className="text-gray-500 fw-semibold fs-7">PM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Mark Morris</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">10:20 - 11:00 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Dashboard UI/UX Design Review</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Peter Marcus</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
											</div>
											{/*end::Tap pane*/}
											{/*begin::Tap pane*/}
											<div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_10">
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-danger"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">12:00 - 13:40 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Peter Marcus</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">10:20 - 11:00 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Dashboard UI/UX Design Review</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Bob</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">16:30 - 17:00 
														<span className="text-gray-500 fw-semibold fs-7">PM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Mark Morris</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
											</div>
											{/*end::Tap pane*/}
											{/*begin::Tap pane*/}
											<div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_11">
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">16:30 - 17:00 
														<span className="text-gray-500 fw-semibold fs-7">PM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Dashboard UI/UX Design Review</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Mark Morris</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-danger"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">10:20 - 11:00 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Peter Marcus</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div className="d-flex align-items-center mb-6">
													{/*begin::Bullet*/}
													<span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-primary"></span>
													{/*end::Bullet*/}
													{/*begin::Info*/}
													<div className="flex-grow-1 me-5">
														{/*begin::Time*/}
														<div className="text-gray-800 fw-semibold fs-2">12:00 - 13:40 
														<span className="text-gray-500 fw-semibold fs-7">AM</span></div>
														{/*end::Time*/}
														{/*begin::Description*/}
														<div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting</div>
														{/*end::Description*/}
														{/*begin::Link*/}
														<div className="text-gray-500 fw-semibold fs-7">Lead by 
														{/*begin::Name*/}
														<a href="#" className="text-primary opacity-75-hover fw-semibold">Lead by Bob</a>
														{/*end::Name*/}</div>
														{/*end::Link*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Wrapper*/}
											</div>
											{/*end::Tap pane*/}
										</div>
										{/*end::Tab Content*/}
										{/*begin::Action*/}
										<div className="float-end d-none">
											<a href="#" className="btn btn-sm btn-light me-2" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">Add Lesson</a>
											<a href="#" className="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app">Call Sick for Today</a>
										</div>
										{/*end::Action*/}
									</div>
									{/*end: Card Body*/}
								</div>
								{/*end::Timeline widget 3*/}
								{/*begin::Timeline widget 3*/}
								<div className="card card-flush d-none h-md-100">
									{/*begin::Card header*/}
									<div className="card-header mt-6">
										{/*begin::Card title*/}
										<div className="card-title flex-column">
											<h3 className="fw-bold mb-1">What's on the road?</h3>
											<div className="fs-6 text-gray-500">Total 482 participants</div>
										</div>
										{/*end::Card title*/}
										{/*begin::Card toolbar*/}
										<div className="card-toolbar">
											{/*begin::Select*/}
											
											{/*end::Select*/}
										</div>
										{/*end::Card toolbar*/}
									</div>
									{/*end::Card header*/}
									{/*begin::Card body*/}
									<div className="card-body p-0">
										{/*begin::Dates*/}
										<ul className="nav nav-pills d-flex flex-nowrap hover-scroll-x py-2 ms-4">
											{/*begin::Date*/}
											<li className="nav-item me-1">
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger" data-bs-toggle="tab" href="#kt_schedule_day_0">
													<span className="text-gray-500 fs-7 fw-semibold">Fr</span>
													<span className="fs-6 text-gray-800 fw-bold">20</span>
												</a>
											</li>
											{/*end::Date*/}
											{/*begin::Date*/}
											<li className="nav-item me-1">
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger" data-bs-toggle="tab" href="#kt_schedule_day_1">
													<span className="text-gray-500 fs-7 fw-semibold">Sa</span>
													<span className="fs-6 text-gray-800 fw-bold">21</span>
												</a>
											</li>
											{/*end::Date*/}
											{/*begin::Date*/}
											<li className="nav-item me-1">
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger" data-bs-toggle="tab" href="#kt_schedule_day_2">
													<span className="text-gray-500 fs-7 fw-semibold">Su</span>
													<span className="fs-6 text-gray-800 fw-bold">22</span>
												</a>
											</li>
											{/*end::Date*/}
											{/*begin::Date*/}
											<li className="nav-item me-1">
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger active" data-bs-toggle="tab" href="#kt_schedule_day_3">
													<span className="text-gray-500 fs-7 fw-semibold">Mo</span>
													<span className="fs-6 text-gray-800 fw-bold">23</span>
												</a>
											</li>
											{/*end::Date*/}
											{/*begin::Date*/}
											<li className="nav-item me-1">
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger" data-bs-toggle="tab" href="#kt_schedule_day_4">
													<span className="text-gray-500 fs-7 fw-semibold">Tu</span>
													<span className="fs-6 text-gray-800 fw-bold">24</span>
												</a>
											</li>
											{/*end::Date*/}
											{/*begin::Date*/}
											<li className="nav-item me-1">
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger" data-bs-toggle="tab" href="#kt_schedule_day_5">
													<span className="text-gray-500 fs-7 fw-semibold">We</span>
													<span className="fs-6 text-gray-800 fw-bold">25</span>
												</a>
											</li>
											{/*end::Date*/}
											{/*begin::Date*/}
											<li className="nav-item me-1">
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger" data-bs-toggle="tab" href="#kt_schedule_day_6">
													<span className="text-gray-500 fs-7 fw-semibold">Th</span>
													<span className="fs-6 text-gray-800 fw-bold">26</span>
												</a>
											</li>
											{/*end::Date*/}
											{/*begin::Date*/}
											<li className="nav-item me-1">
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger" data-bs-toggle="tab" href="#kt_schedule_day_7">
													<span className="text-gray-500 fs-7 fw-semibold">Fr</span>
													<span className="fs-6 text-gray-800 fw-bold">27</span>
												</a>
											</li>
											{/*end::Date*/}
											{/*begin::Date*/}
											<li className="nav-item me-1">
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger" data-bs-toggle="tab" href="#kt_schedule_day_8">
													<span className="text-gray-500 fs-7 fw-semibold">Sa</span>
													<span className="fs-6 text-gray-800 fw-bold">28</span>
												</a>
											</li>
											{/*end::Date*/}
											{/*begin::Date*/}
											<li className="nav-item me-1">
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger" data-bs-toggle="tab" href="#kt_schedule_day_9">
													<span className="text-gray-500 fs-7 fw-semibold">Su</span>
													<span className="fs-6 text-gray-800 fw-bold">29</span>
												</a>
											</li>
											{/*end::Date*/}
											{/*begin::Date*/}
											<li className="nav-item me-1">
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger" data-bs-toggle="tab" href="#kt_schedule_day_10">
													<span className="text-gray-500 fs-7 fw-semibold">Mo</span>
													<span className="fs-6 text-gray-800 fw-bold">30</span>
												</a>
											</li>
											{/*end::Date*/}
											{/*begin::Date*/}
											<li className="nav-item me-1">
												<a className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger" data-bs-toggle="tab" href="#kt_schedule_day_11">
													<span className="text-gray-500 fs-7 fw-semibold">Tu</span>
													<span className="fs-6 text-gray-800 fw-bold">31</span>
												</a>
											</li>
											{/*end::Date*/}
										</ul>
										{/*end::Dates*/}
										{/*begin::Tab Content*/}
										<div className="tab-content px-9">
											{/*begin::Day*/}
											<div id="kt_schedule_day_0" className="tab-pane fade show">
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">9:00 - 10:00 
														<span className="fs-7 text-gray-500 text-uppercase">am</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Project Review & Testing</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Michael Walters</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">13:00 - 14:00 
														<span className="fs-7 text-gray-500 text-uppercase">pm</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Sales Pitch Proposal</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Mark Randall</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">10:00 - 11:00 
														<span className="fs-7 text-gray-500 text-uppercase">am</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Marketing Campaign Discussion</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Terry Robins</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
											</div>
											{/*end::Day*/}
											{/*begin::Day*/}
											<div id="kt_schedule_day_1" className="tab-pane fade show active">
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">12:00 - 13:00 
														<span className="fs-7 text-gray-500 text-uppercase">pm</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Team Backlog Grooming Session</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Peter Marcus</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">16:30 - 17:30 
														<span className="fs-7 text-gray-500 text-uppercase">pm</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Lunch & Learn Catch Up</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Michael Walters</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">10:00 - 11:00 
														<span className="fs-7 text-gray-500 text-uppercase">am</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Project Review & Testing</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Naomi Hayabusa</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
											</div>
											{/*end::Day*/}
											{/*begin::Day*/}
											<div id="kt_schedule_day_2" className="tab-pane fade show">
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">9:00 - 10:00 
														<span className="fs-7 text-gray-500 text-uppercase">am</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Weekly Team Stand-Up</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Caleb Donaldson</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">9:00 - 10:00 
														<span className="fs-7 text-gray-500 text-uppercase">am</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Lunch & Learn Catch Up</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">David Stevenson</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">11:00 - 11:45 
														<span className="fs-7 text-gray-500 text-uppercase">am</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Marketing Campaign Discussion</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Terry Robins</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
											</div>
											{/*end::Day*/}
											{/*begin::Day*/}
											<div id="kt_schedule_day_3" className="tab-pane fade show">
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">11:00 - 11:45 
														<span className="fs-7 text-gray-500 text-uppercase">am</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Creative Content Initiative</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Michael Walters</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">9:00 - 10:00 
														<span className="fs-7 text-gray-500 text-uppercase">am</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">9 Degree Project Estimation Meeting</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Kendell Trevor</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">9:00 - 10:00 
														<span className="fs-7 text-gray-500 text-uppercase">am</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Lunch & Learn Catch Up</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Yannis Gloverson</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
											</div>
											{/*end::Day*/}
											{/*begin::Day*/}
											<div id="kt_schedule_day_4" className="tab-pane fade show">
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">11:00 - 11:45 
														<span className="fs-7 text-gray-500 text-uppercase">am</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Team Backlog Grooming Session</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Michael Walters</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">9:00 - 10:00 
														<span className="fs-7 text-gray-500 text-uppercase">am</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Project Review & Testing</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Mark Randall</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">16:30 - 17:30 
														<span className="fs-7 text-gray-500 text-uppercase">pm</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Marketing Campaign Discussion</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Naomi Hayabusa</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
											</div>
											{/*end::Day*/}
											{/*begin::Day*/}
											<div id="kt_schedule_day_5" className="tab-pane fade show">
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">16:30 - 17:30 
														<span className="fs-7 text-gray-500 text-uppercase">pm</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Weekly Team Stand-Up</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Bob Harris</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">14:30 - 15:30 
														<span className="fs-7 text-gray-500 text-uppercase">pm</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Marketing Campaign Discussion</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Karina Clarke</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">10:00 - 11:00 
														<span className="fs-7 text-gray-500 text-uppercase">am</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Project Review & Testing</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Kendell Trevor</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
											</div>
											{/*end::Day*/}
											{/*begin::Day*/}
											<div id="kt_schedule_day_6" className="tab-pane fade show">
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">12:00 - 13:00 
														<span className="fs-7 text-gray-500 text-uppercase">pm</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Committee Review Approvals</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Naomi Hayabusa</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">14:30 - 15:30 
														<span className="fs-7 text-gray-500 text-uppercase">pm</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Creative Content Initiative</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Michael Walters</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">13:00 - 14:00 
														<span className="fs-7 text-gray-500 text-uppercase">pm</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Team Backlog Grooming Session</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Yannis Gloverson</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
											</div>
											{/*end::Day*/}
											{/*begin::Day*/}
											<div id="kt_schedule_day_7" className="tab-pane fade show">
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">11:00 - 11:45 
														<span className="fs-7 text-gray-500 text-uppercase">am</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Creative Content Initiative</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Yannis Gloverson</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">11:00 - 11:45 
														<span className="fs-7 text-gray-500 text-uppercase">am</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">9 Degree Project Estimation Meeting</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">David Stevenson</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">13:00 - 14:00 
														<span className="fs-7 text-gray-500 text-uppercase">pm</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Team Backlog Grooming Session</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">David Stevenson</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
											</div>
											{/*end::Day*/}
											{/*begin::Day*/}
											<div id="kt_schedule_day_8" className="tab-pane fade show">
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">10:00 - 11:00 
														<span className="fs-7 text-gray-500 text-uppercase">am</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Lunch & Learn Catch Up</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Naomi Hayabusa</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">9:00 - 10:00 
														<span className="fs-7 text-gray-500 text-uppercase">am</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Sales Pitch Proposal</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Sean Bean</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">12:00 - 13:00 
														<span className="fs-7 text-gray-500 text-uppercase">pm</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Sales Pitch Proposal</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Michael Walters</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
											</div>
											{/*end::Day*/}
											{/*begin::Day*/}
											<div id="kt_schedule_day_9" className="tab-pane fade show">
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">12:00 - 13:00 
														<span className="fs-7 text-gray-500 text-uppercase">pm</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Creative Content Initiative</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">David Stevenson</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">9:00 - 10:00 
														<span className="fs-7 text-gray-500 text-uppercase">am</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Creative Content Initiative</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Yannis Gloverson</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">13:00 - 14:00 
														<span className="fs-7 text-gray-500 text-uppercase">pm</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Marketing Campaign Discussion</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Caleb Donaldson</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
											</div>
											{/*end::Day*/}
											{/*begin::Day*/}
											<div id="kt_schedule_day_10" className="tab-pane fade show">
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">11:00 - 11:45 
														<span className="fs-7 text-gray-500 text-uppercase">am</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Development Team Capacity Review</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Terry Robins</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">14:30 - 15:30 
														<span className="fs-7 text-gray-500 text-uppercase">pm</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Dashboard UI/UX Design Review</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Naomi Hayabusa</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">13:00 - 14:00 
														<span className="fs-7 text-gray-500 text-uppercase">pm</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Team Backlog Grooming Session</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Kendell Trevor</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
											</div>
											{/*end::Day*/}
											{/*begin::Day*/}
											<div id="kt_schedule_day_11" className="tab-pane fade show">
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">12:00 - 13:00 
														<span className="fs-7 text-gray-500 text-uppercase">pm</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Lunch & Learn Catch Up</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Naomi Hayabusa</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">14:30 - 15:30 
														<span className="fs-7 text-gray-500 text-uppercase">pm</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Project Review & Testing</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">David Stevenson</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
												{/*begin::Time*/}
												<div className="d-flex flex-stack position-relative mt-8">
													{/*begin::Bar*/}
													<div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
													{/*end::Bar*/}
													{/*begin::Info*/}
													<div className="fw-semibold ms-5 text-gray-600">
														{/*begin::Time*/}
														<div className="fs-5">12:00 - 13:00 
														<span className="fs-7 text-gray-500 text-uppercase">pm</span></div>
														{/*end::Time*/}
														{/*begin::Title*/}
														<a href="#" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Committee Review Approvals</a>
														{/*end::Title*/}
														{/*begin::User*/}
														<div className="text-gray-500">Lead by 
														<a href="#">Mark Randall</a></div>
														{/*end::User*/}
													</div>
													{/*end::Info*/}
													{/*begin::Action*/}
													<a href="#" className="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
													{/*end::Action*/}
												</div>
												{/*end::Time*/}
											</div>
											{/*end::Day*/}
										</div>
										{/*end::Tab Content*/}
									</div>
									{/*end::Card body*/}
								</div>
								{/*end::Timeline widget-3*/}
							</div>
							{/*end::Col*/}
						</div>
					
					{/*end::Row*/}
					
					{/*begin::Row*/}
					<div className="row g-5 g-xl-10">
						{/*begin::Col*/}
						<div className="col-xl-4">
							{/*begin::Engage widget 1*/}
							<div className="card h-md-100" dir="ltr">
								{/*begin::Body*/}
								<div className="card-body d-flex flex-column flex-center">
									{/*begin::Heading*/}
									<div className="mb-2">
										{/*begin::Title*/}
										<h1 className="fw-semibold text-gray-800 text-center lh-lg">Have your tried 
										<br />new 
										<span className="fw-bolder">Invoice Manager?</span></h1>
										{/*end::Title*/}
										{/*begin::Illustration*/}
										<div className="py-10 text-center">
											<img src="assets/media/svg/illustrations/easy/2.svg" className="theme-light-show w-200px" alt="" />
											<img src="assets/media/svg/illustrations/easy/2-dark.svg" className="theme-dark-show w-200px" alt="" />
										</div>
										{/*end::Illustration*/}
									</div>
									{/*end::Heading*/}
									{/*begin::Links*/}
									<div className="text-center mb-1">
										{/*begin::Link*/}
										<a className="btn btn-sm btn-primary me-2" data-bs-target="#kt_modal_new_address" data-bs-toggle="modal">Try Now</a>
										{/*end::Link*/}
										{/*begin::Link*/}
										<a className="btn btn-sm btn-light" href="apps/user-management/users/view.html">Learn More</a>
										{/*end::Link*/}
									</div>
									{/*end::Links*/}
								</div>
								{/*end::Body*/}
							</div>
							{/*end::Engage widget 1*/}
						</div>
						{/*end::Col*/}
						{/*begin::Col*/}
						<div className="col-xl-8">
							{/*begin::Timeline Widget 4*/}
							<div className="card h-md-100">
								{/*begin::Card header*/}
								<div className="card-header position-relative py-0 border-bottom-1">
									{/*begin::Card title*/}
									<h3 className="card-title text-gray-800 fw-bold">Active Tasks</h3>
									{/*end::Card title*/}
									{/*begin::Tabs*/}
									<ul className="nav nav-stretch nav-pills nav-pills-custom d-flex mt-4">
										{/*begin::Nav item*/}
										<li className="nav-item p-0 ms-0">
											<a className="nav-link btn btn-color-gray-500 flex-center px-3 active" data-kt-timeline-widget-4="tab" data-bs-toggle="tab" href="#kt_timeline_widget_4_tab_day">
												{/*begin::Title*/}
												<span className="nav-text fw-semibold fs-4 mb-3">Day</span>
												{/*end::Title*/}
												{/*begin::Bullet*/}
												<span className="bullet-custom position-absolute z-index-2 w-100 h-1px top-100 bottom-n100 bg-primary rounded"></span>
												{/*end::Bullet*/}
											</a>
										</li>
										{/*end::Nav item*/}
										{/*begin::Nav item*/}
										<li className="nav-item p-0 ms-0">
											<a className="nav-link btn btn-color-gray-500 flex-center px-3" data-kt-timeline-widget-4="tab" data-bs-toggle="tab" href="#kt_timeline_widget_4_tab_week">
												{/*begin::Title*/}
												<span className="nav-text fw-semibold fs-4 mb-3">Week</span>
												{/*end::Title*/}
												{/*begin::Bullet*/}
												<span className="bullet-custom position-absolute z-index-2 w-100 h-1px top-100 bottom-n100 bg-primary rounded"></span>
												{/*end::Bullet*/}
											</a>
										</li>
										{/*end::Nav item*/}
										{/*begin::Nav item*/}
										<li className="nav-item p-0 ms-0">
											<a className="nav-link btn btn-color-gray-500 flex-center px-3" data-kt-timeline-widget-4="tab" data-bs-toggle="tab" href="#kt_timeline_widget_4_tab_month">
												{/*begin::Title*/}
												<span className="nav-text fw-semibold fs-4 mb-3">Month</span>
												{/*end::Title*/}
												{/*begin::Bullet*/}
												<span className="bullet-custom position-absolute z-index-2 w-100 h-1px top-100 bottom-n100 bg-primary rounded"></span>
												{/*end::Bullet*/}
											</a>
										</li>
										{/*end::Nav item*/}
										{/*begin::Nav item*/}
										<li className="nav-item p-0 ms-0">
											<a className="nav-link btn btn-color-gray-500 flex-center px-3" data-kt-timeline-widget-4="tab" data-bs-toggle="tab" href="#kt_timeline_widget_4_tab_2022">
												{/*begin::Title*/}
												<span className="nav-text fw-semibold fs-4 mb-3">2022</span>
												{/*end::Title*/}
												{/*begin::Bullet*/}
												<span className="bullet-custom position-absolute z-index-2 w-100 h-1px top-100 bottom-n100 bg-primary rounded"></span>
												{/*end::Bullet*/}
											</a>
										</li>
										{/*end::Nav item*/}
									</ul>
									{/*end::Tabs*/}
								</div>
								{/*end::Card header*/}
								{/*begin::Card body*/}
								<div className="card-body pb-0">
									{/*begin::Tab content*/}
									<div className="tab-content">
										{/*begin::Tab pane*/}
										<div className="tab-pane active" id="kt_timeline_widget_4_tab_day" role="tabpanel" aria-labelledby="day-tab" data-kt-timeline-widget-4-blockui="true">
											<div className="table-responsive pb-10">
												{/*begin::Timeline*/}
												<div id="kt_timeline_widget_4_1" className="vis-timeline-custom h-350px min-w-700px" data-kt-timeline-widget-4-image-root="assets/media/"></div>
												{/*end::Timeline*/}
											</div>
										</div>
										{/*end::Tab pane*/}
										{/*begin::Tab pane*/}
										<div className="tab-pane" id="kt_timeline_widget_4_tab_week" role="tabpanel" aria-labelledby="week-tab" data-kt-timeline-widget-4-blockui="true">
											<div className="table-responsive pb-10">
												{/*begin::Timeline*/}
												<div id="kt_timeline_widget_4_2" className="vis-timeline-custom h-350px min-w-700px" data-kt-timeline-widget-4-image-root="assets/media/"></div>
												{/*end::Timeline*/}
											</div>
										</div>
										{/*end::Tab pane*/}
										{/*begin::Tab pane*/}
										<div className="tab-pane" id="kt_timeline_widget_4_tab_month" role="tabpanel" aria-labelledby="month-tab" data-kt-timeline-widget-4-blockui="true">
											<div className="table-responsive pb-10">
												{/*begin::Timeline*/}
												<div id="kt_timeline_widget_4_3" className="vis-timeline-custom h-350px min-w-700px" data-kt-timeline-widget-4-image-root="assets/media/"></div>
												{/*end::Timeline*/}
											</div>
										</div>
										{/*end::Tab pane*/}
										{/*begin::Tab pane*/}
										<div className="tab-pane" id="kt_timeline_widget_4_tab_2022" role="tabpanel" aria-labelledby="week-tab" data-kt-timeline-widget-4-blockui="true">
											<div className="table-responsive pb-10">
												{/*begin::Timeline*/}
												<div id="kt_timeline_widget_4_4" className="vis-timeline-custom h-350px min-w-700px" data-kt-timeline-widget-4-image-root="assets/media/"></div>
												{/*end::Timeline*/}
											</div>
										</div>
										{/*end::Tab pane*/}
									</div>
									{/*end::Tab content*/}
								</div>
								{/*end::Card body*/}
							</div>
							{/*end::Timeline Widget 1*/}
						</div>
						{/*end::Col*/}
					</div>
					{/*end::Row*/}
				</div>
				{/*end::Content*/}
			</div>
			{/*end::Content wrapper*/}
			<Footer/>
		</div>
    );
}
export default Content
import  { React, useState } from 'react';
import Header from '../layout/header.jsx';
import Aside from '../layout/aside.jsx';
import Rapport from './rapports.jsx';
import Indicateur from './indicateurs.jsx';
function Pilotage() {
    const [activeComponent, setActiveComponent] = useState('rapport');

    const handleButtonClick = (component) => {
        setActiveComponent(component);
    };
    return (
		<div className="d-flex flex-column flex-root app-root" >
			<div className="app-page flex-column flex-column-fluid" id="kt_app_page">
				{/*begin::Header*/}
				<Header />
				{/*end::Header*/}
				{/*begin::Wrapper*/}
				<div className="app-wrapper flex-column flex-row-fluid" style={{ paddingTop: '180px' }} id="kt_app_wrapper">
					{/*begin::Wrapper container*/}
					<div className="app-container container-fluid d-flex flex-row-fluid">
						{/*begin::Sidebar*/}
						<Aside />
						{/*end::Sidebar*/}
						{/*begin::Main*/}
						<div className="app-main flex-column flex-row-fluid" id="kt_app_main">
							{/*begin::Content wrapper*/}
							<div className="d-flex flex-column flex-column-fluid">
								{/*begin::Toolbar*/}
								<div id="kt_app_toolbar" className="app-toolbar d-flex pb-3 pb-lg-5">
								{/*begin::Toolbar container*/}
								<div className="d-flex flex-stack flex-row-fluid">
									{/*begin::Toolbar container*/}
									<div className="d-flex flex-column flex-row-fluid">
									{/*begin::Toolbar wrapper*/}
									{/*begin::Page title*/}
									<div className="page-title d-flex align-items-center me-3">
										{/*begin::Title*/}
										<h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-lg-2x gap-2">
										<span>Pilotage</span>
										</h1>
										{/*end::Title*/}
									</div>
									{/*end::Page title*/}
									{/*begin::Breadcrumb*/}
									<ul className="breadcrumb breadcrumb-separatorless fw-semibold mb-3 fs-7">
										{/*begin::Item*/}
										<li className="breadcrumb-item text-gray-700 fw-bold lh-1">
										<a href="index.html" className="text-white text-hover-primary">
											<i className="ki-outline ki-home text-gray-700 fs-6"></i>
										</a>
										</li>
										{/*end::Item*/}
										{/*begin::Item*/}
										<li className="breadcrumb-item">
										<i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
										</li>
										{/*end::Item*/}
										{/*begin::Item*/}
										<li className="breadcrumb-item text-gray-700 fw-bold lh-1">Dashboards</li>
										{/*end::Item*/}
										{/*begin::Item*/}
										<li className="breadcrumb-item">
										<i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
										</li>
										{/*end::Item*/}
										{/*begin::Item*/}
										<li className="breadcrumb-item text-gray-700">Pilotage</li>
										{/*end::Item*/}
									</ul>
									{/*end::Breadcrumb*/}
									</div>
									{/*end::Toolbar container*/}
								</div>
								{/*end::Toolbar container*/}
								</div>
								{/*end::Toolbar*/}
								{/*begin::Content*/}
								<div id="kt_app_content" className="app-content flex-column-fluid">						
									{/*begin::Card*/}
										<div
											className="card card-flush pb-0 bgi-position-y-center bgi-no-repeat mb-10"
											style={{
											backgroundSize: "auto calc(100% + 10rem)",
											backgroundPositionX: "100%",
											backgroundImage: 'url("assets/media/illustrations/sketchy-1/4.png")'
											}}
										>
											{/*begin::Card header*/}
											<div className="card-header pt-10">
											<div className="d-flex align-items-center">
												{/*begin::Icon*/}
												<div className="symbol symbol-circle me-5">
												<div className="symbol-label bg-transparent text-primary border border-secondary border-dashed">
													<i className="ki-outline ki-abstract-47 fs-2x text-primary" />
												</div>
												</div>
												{/*end::Icon*/}
												{/*begin::Title*/}
												<div className="d-flex flex-column">
												<h2 className="mb-1">Pilotage</h2>
												
												</div>
												{/*end::Title*/}
											</div>
											</div>
											{/*end::Card header*/}
											{/*begin::Card body*/}
											<div className="card-body pb-0">
											{/*begin::Navs*/}
											<div className="d-flex overflow-auto h-55px">
												<ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap">
												{/*begin::Nav item*/}
												<li className="nav-item">
													<a
													className={`nav-link text-active-primary me-6 ${activeComponent === 'rapport' ? 'active' : ''}`}
													onClick={() => handleButtonClick('rapport')} 
													>
													Rapports
													</a>
												</li>
												{/*end::Nav item*/}
												{/*begin::Nav item*/}
												<li className="nav-item">
													<a
													className={`nav-link text-active-primary me-6 ${activeComponent === 'indicateur' ? 'active' : ''}`}
													onClick={() => handleButtonClick('indicateur')}
													>
													Indicateurs
													</a>
												</li>
												{/*end::Nav item*/}
												</ul>
											</div>
											{/*begin::Navs*/}
											</div>
											{/*end::Card body*/}
										</div>
									{/*end::Card*/}
									<div>
										{activeComponent === 'rapport' && <Rapport />}
										{activeComponent === 'indicateur' && <Indicateur />}
									</div>

								</div>
								{/*end::Content*/}
							</div>
							{/*end::Content wrapper*/}
							</div>
						{/*end:::Main*/}
					</div>
					{/*end::Wrapper container*/}
				</div>
				{/*end::Wrapper*/}
			</div>
		</div>
 
    );
}
export default Pilotage
// create header
import React from 'react';
import { Link } from 'react-router-dom';

function Header(){
	const htmlCode = "data-kt-swapper-parent=\"{default: '#kt_app_body', lg: '#kt_app_header_menu_wrapper'}\"";

	return(
		<div id="kt_app_header" style={{position: 'fixed',top: 0,width: '100%',zIndex: 1000,backgroundColor: '#e4eff9'}} className="app-header" data-kt-sticky="true" data-kt-sticky-activate-="true" data-kt-sticky-name="app-header-sticky" data-kt-sticky-offset="{default: '200px', lg: '300px'}">
			{/*begin::Header container*/}
			<div className="app-container container-fluid d-flex align-items-stretch justify-content-between" id="kt_app_header_container">
				{/*begin::Header wrapper*/}
				<div className="app-header-wrapper d-flex flex-grow-1 align-items-stretch justify-content-between" id="kt_app_header_wrapper">
					{/*begin::Logo wrapper*/}
					<div className="app-header-logo d-flex flex-shrink-0 align-items-center justify-content-between justify-content-lg-center">
						{/*begin::Logo wrapper*/}
						<button className="btn btn-icon btn-color-gray-600 btn-active-color-primary ms-n3 me-2 d-flex d-lg-none" id="kt_app_sidebar_toggle">
							<i className="ki-outline ki-abstract-14 fs-2"></i>
						</button>
						{/*end::Logo wrapper*/}
						{/*begin::Logo image*/}
						<a href="index.html">
							<img alt="Logo" src="assets/media/logos/default-small.svg" className="h-30px h-lg-40px theme-light-show" />
							<img alt="Logo" src="assets/media/logos/default-small-dark.svg" className="h-30px h-lg-40px theme-dark-show" />
						</a>
						{/*end::Logo image*/}
					</div>
					{/*end::Logo wrapper*/}
					{/*begin::Menu wrapper*/}
					<div id="kt_app_header_menu_wrapper" className="d-flex align-items-center w-100">
						{/*begin::Header menu*/}
						<div className="app-header-menu app-header-mobile-drawer align-items-start align-items-lg-center w-100" data-kt-drawer="true" data-kt-drawer-name="app-header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="250px" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_app_header_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"   data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_menu_wrapper'}">
							{/*begin::Menu*/}
							<div className="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-state-primary menu-title-gray-700 menu-arrow-gray-500 menu-bullet-gray-500 my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0" id="#kt_header_menu" data-kt-menu="true">
								{/*begin:Menu item*/}
								<div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" data-kt-menu-offset="-100,0" className="menu-item here show menu-here-bg menu-lg-down-accordion me-0 me-lg-2">
									{/*begin:Menu link*/}
									<span className="menu-link">
									<Link to="/home">
										<span className="menu-title">Accueil</span>
									</Link>
										<span className="menu-arrow d-lg-none"></span>
									</span>
									{/*end:Menu link*/}
									
								</div>
								{/*end:Menu item*/}
								{/*begin:Menu item*/}
								<div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" data-kt-menu-offset="-300,0" className="menu-item menu-lg-down-accordion me-0 me-lg-2">
									{/*begin:Menu link*/}
									<span className="menu-link">
										<span className="menu-title">Rapport</span>
										<span className="menu-arrow d-lg-none"></span>
									</span>
									{/*end:Menu link*/}
									
								</div>
								{/*end:Menu item*/}
								{/*begin:Menu item*/}
								<div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" className="menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2">
									{/*begin:Menu link*/}
									<span className="menu-link">
										<span className="menu-title">EUDR</span>
										<span className="menu-arrow d-lg-none"></span>
									</span>
									{/*end:Menu link*/}
									
								</div>
								{/*end:Menu item*/}
								{/*begin:Menu item*/}
								<div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" className="menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2">
									{/*begin:Menu link*/}
									<span className="menu-link">
										<span className="menu-title">Admin</span>
										<span className="menu-arrow d-lg-none"></span>
									</span>
									{/*end:Menu link*/}
									
								</div>
								{/*end:Menu item*/}
							</div>
							{/*end::Menu*/}
						</div>
						{/*end::Header menu*/}
					</div>
					{/*end::Menu wrapper*/}
					{/*begin::Navbar*/}
					<div className="app-navbar flex-shrink-0">
						
						
						
						
						{/*begin::User menu*/}
						<div className="app-navbar-item ms-3 ms-lg-5" id="kt_header_user_menu_toggle">
							{/*begin::Menu wrapper*/}
							<div className="cursor-pointer symbol symbol-35px symbol-md-40px" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
								<img className="symbol symbol-circle symbol-35px symbol-md-40px" src="assets/media/avatars/300-13.jpg" alt="user" />
							</div>
							{/*begin::User account menu*/}
							<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px" data-kt-menu="true">
								{/*begin::Menu item*/}
								<div className="menu-item px-3">
									<div className="menu-content d-flex align-items-center px-3">
										{/*begin::Avatar*/}
										<div className="symbol symbol-50px me-5">
											<img alt="Logo" src="assets/media/avatars/300-13.jpg" />
										</div>
										{/*end::Avatar*/}
										{/*begin::Username*/}
										<div className="d-flex flex-column">
											<div className="fw-bold d-flex align-items-center fs-5">Max Smith 
											<span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">Pro</span></div>
											<a href="#" className="fw-semibold text-muted text-hover-primary fs-7">max@kt.com</a>
										</div>
										{/*end::Username*/}
									</div>
								</div>
								{/*end::Menu item*/}
								{/*begin::Menu separator*/}
								<div className="separator my-2"></div>
								{/*end::Menu separator*/}
								{/*begin::Menu item*/}
								<div className="menu-item px-5">
									<a href="account/overview.html" className="menu-link px-5">My Profile</a>
								</div>
								{/*end::Menu item*/}
								{/*begin::Menu item*/}
								<div className="menu-item px-5">
									<a href="apps/projects/list.html" className="menu-link px-5">
										<span className="menu-text">My Projects</span>
										<span className="menu-badge">
											<span className="badge badge-light-danger badge-circle fw-bold fs-7">3</span>
										</span>
									</a>
								</div>
								{/*end::Menu item*/}
								{/*begin::Menu item*/}
								<div className="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="left-start" data-kt-menu-offset="-15px, 0">
									<a href="#" className="menu-link px-5">
										<span className="menu-title">My Subscription</span>
										<span className="menu-arrow"></span>
									</a>
									{/*begin::Menu sub*/}
									<div className="menu-sub menu-sub-dropdown w-175px py-4">
										{/*begin::Menu item*/}
										<div className="menu-item px-3">
											<a href="account/referrals.html" className="menu-link px-5">Referrals</a>
										</div>
										{/*end::Menu item*/}
										{/*begin::Menu item*/}
										<div className="menu-item px-3">
											<a href="account/billing.html" className="menu-link px-5">Billing</a>
										</div>
										{/*end::Menu item*/}
										{/*begin::Menu item*/}
										<div className="menu-item px-3">
											<a href="account/statements.html" className="menu-link px-5">Payments</a>
										</div>
										{/*end::Menu item*/}
										{/*begin::Menu item*/}
										<div className="menu-item px-3">
											<a href="account/statements.html" className="menu-link d-flex flex-stack px-5">Statements 
											<span className="ms-2 lh-0" data-bs-toggle="tooltip" title="View your statements">
												<i className="ki-outline ki-information-5 fs-5"></i>
											</span></a>
										</div>
										{/*end::Menu item*/}
										{/*begin::Menu separator*/}
										<div className="separator my-2"></div>
										{/*end::Menu separator*/}
										{/*begin::Menu item*/}
										<div className="menu-item px-3">
											<div className="menu-content px-3">
												<label className="form-check form-switch form-check-custom form-check-solid">
													<input className="form-check-input w-30px h-20px" type="checkbox" value="1" defaultChecked="checked" name="notifications" />
													<span className="form-check-label text-muted fs-7">Notifications</span>
												</label>
											</div>
										</div>
										{/*end::Menu item*/}
									</div>
									{/*end::Menu sub*/}
								</div>
								{/*end::Menu item*/}
								{/*begin::Menu item*/}
								<div className="menu-item px-5">
									<a href="account/statements.html" className="menu-link px-5">My Statements</a>
								</div>
								{/*end::Menu item*/}
								{/*begin::Menu separator*/}
								<div className="separator my-2"></div>
								{/*end::Menu separator*/}
								{/*begin::Menu item*/}
								<div className="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="left-start" data-kt-menu-offset="-15px, 0">
									<a href="#" className="menu-link px-5">
										<span className="menu-title position-relative">Mode 
										<span className="ms-5 position-absolute translate-middle-y top-50 end-0">
											<i className="ki-outline ki-night-day theme-light-show fs-2"></i>
											<i className="ki-outline ki-moon theme-dark-show fs-2"></i>
										</span></span>
									</a>
									{/*begin::Menu*/}
									<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px" data-kt-menu="true" data-kt-element="theme-mode-menu">
										{/*begin::Menu item*/}
										<div className="menu-item px-3 my-0">
											<a href="#" className="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="light">
												<span className="menu-icon" data-kt-element="icon">
													<i className="ki-outline ki-night-day fs-2"></i>
												</span>
												<span className="menu-title">Light</span>
											</a>
										</div>
										{/*end::Menu item*/}
										{/*begin::Menu item*/}
										<div className="menu-item px-3 my-0">
											<a href="#" className="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="dark">
												<span className="menu-icon" data-kt-element="icon">
													<i className="ki-outline ki-moon fs-2"></i>
												</span>
												<span className="menu-title">Dark</span>
											</a>
										</div>
										{/*end::Menu item*/}
										{/*begin::Menu item*/}
										<div className="menu-item px-3 my-0">
											<a href="#" className="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="system">
												<span className="menu-icon" data-kt-element="icon">
													<i className="ki-outline ki-screen fs-2"></i>
												</span>
												<span className="menu-title">System</span>
											</a>
										</div>
										{/*end::Menu item*/}
									</div>
									{/*end::Menu*/}
								</div>
								{/*end::Menu item*/}
								{/*begin::Menu item*/}
								<div className="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="left-start" data-kt-menu-offset="-15px, 0">
									<a href="#" className="menu-link px-5">
										<span className="menu-title position-relative">Language 
										<span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">English 
										<img className="w-15px h-15px rounded-1 ms-2" src="assets/media/flags/united-states.svg" alt="" /></span></span>
									</a>
									{/*begin::Menu sub*/}
									<div className="menu-sub menu-sub-dropdown w-175px py-4">
										{/*begin::Menu item*/}
										<div className="menu-item px-3">
											<a href="account/settings.html" className="menu-link d-flex px-5 active">
											<span className="symbol symbol-20px me-4">
												<img className="rounded-1" src="assets/media/flags/united-states.svg" alt="" />
											</span>English</a>
										</div>
										{/*end::Menu item*/}
										{/*begin::Menu item*/}
										<div className="menu-item px-3">
											<a href="account/settings.html" className="menu-link d-flex px-5">
											<span className="symbol symbol-20px me-4">
												<img className="rounded-1" src="assets/media/flags/spain.svg" alt="" />
											</span>Spanish</a>
										</div>
										{/*end::Menu item*/}
										{/*begin::Menu item*/}
										<div className="menu-item px-3">
											<a href="account/settings.html" className="menu-link d-flex px-5">
											<span className="symbol symbol-20px me-4">
												<img className="rounded-1" src="assets/media/flags/germany.svg" alt="" />
											</span>German</a>
										</div>
										{/*end::Menu item*/}
										{/*begin::Menu item*/}
										<div className="menu-item px-3">
											<a href="account/settings.html" className="menu-link d-flex px-5">
											<span className="symbol symbol-20px me-4">
												<img className="rounded-1" src="assets/media/flags/japan.svg" alt="" />
											</span>Japanese</a>
										</div>
										{/*end::Menu item*/}
										{/*begin::Menu item*/}
										<div className="menu-item px-3">
											<a href="account/settings.html" className="menu-link d-flex px-5">
											<span className="symbol symbol-20px me-4">
												<img className="rounded-1" src="assets/media/flags/france.svg" alt="" />
											</span>French</a>
										</div>
										{/*end::Menu item*/}
									</div>
									{/*end::Menu sub*/}
								</div>
								{/*end::Menu item*/}
								{/*begin::Menu item*/}
								<div className="menu-item px-5 my-1">
									<a href="account/settings.html" className="menu-link px-5">Account Settings</a>
								</div>
								{/*end::Menu item*/}
								{/*begin::Menu item*/}
								<div className="menu-item px-5">
									<a href="authentication/layouts/corporate/sign-in.html" className="menu-link px-5">Sign Out</a>
								</div>
								{/*end::Menu item*/}
							</div>
							{/*end::User account menu*/}
							{/*end::Menu wrapper*/}
						</div>
						{/*end::User menu*/}
						{/*begin::Header menu toggle*/}
						<div className="app-navbar-item d-lg-none ms-2 me-n3" title="Show header menu">
							<div className="btn btn-icon btn-custom btn-active-color-primary btn-color-gray-700 w-35px h-35px w-md-40px h-md-40px" id="kt_app_header_menu_toggle">
								<i className="ki-outline ki-text-align-left fs-1"></i>
							</div>
						</div>
						{/*end::Header menu toggle*/}
					</div>
					{/*end::Navbar*/}
				</div>
				{/*end::Header wrapper*/}
			</div>
			{/*end::Header container*/}
		</div>
	);		
	  
	
		
}
export default Header
//create home page

import React from 'react';
import Content from '../home/content.jsx';
import Header from '../layout/header.jsx';
import Aside from '../layout/aside.jsx';
function Home() {


    return (
		<div className="d-flex flex-column flex-root app-root" >
			<div className="app-page flex-column flex-column-fluid" id="kt_app_page">
				{/*begin::Header*/}
				<Header />
				{/*end::Header*/}
				{/*begin::Wrapper*/}
				<div className="app-wrapper flex-column flex-row-fluid" style={{ paddingTop: '180px' }} id="kt_app_wrapper">
					{/*begin::Wrapper container*/}
					<div className="app-container container-fluid d-flex flex-row-fluid">
						{/*begin::Sidebar*/}
						<Aside />
						{/*end::Sidebar*/}
						{/*begin::Main*/}
						<Content />
						{/*end:::Main*/}
					</div>
					{/*end::Wrapper container*/}
				</div>
				{/*end::Wrapper*/}
			</div>
		</div>
		
        
    );
}
export default Home
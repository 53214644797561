import { useState, useEffect } from 'react';
import 'reactjs-popup/dist/index.css';
import "./popUpStyle.css";
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import NumberCard from './numberCard.jsx';
import List from './List.jsx';
import { BODY } from '../../constant.js';

function PopUp({ nomGraphe, typeGraphe, endpoint, typeAction, modal, toggle, args }) {
  const [apiData, setApiData] = useState({});
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${endpoint}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            "zoneId": "0",
            "groupementId": "0",
            "inspectionId": "34",
            "produitId": "0",
            "abonneId": "94",
            "agentId": "0",
            "campagneId": "0",
            "typeAction": typeAction
          })
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch. Status: ${response.status}`);
        }

        const data = await response.json();
        setApiData(data);
        console.log('apiData', data);

      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();

  }, [endpoint, typeAction, token]);

  const dynamicColors = () => {
    const colors = [];
    if (apiData.data && apiData.data.labels) {
      for (let i = 0; i < apiData.data.labels.length; i++) {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        colors.push(`rgb(${r},${g},${b})`);
      }
    }
    return colors;
  };

  const pieChartData = {
    labels: apiData.data ? apiData.data.labels : [],
    datasets: [
      {
        label: nomGraphe,
        backgroundColor: dynamicColors(),
        hoverBackgroundColor: dynamicColors(),
        data: apiData.data ? apiData.data.data : []
      }
    ]
  };

  const donughtChartData = {
    labels: apiData.data ? apiData.data.labels : [],
    datasets: [
      {
        label: nomGraphe,
        backgroundColor: dynamicColors(),
        hoverBackgroundColor: dynamicColors(),
        data: apiData.data ? apiData.data.data : []
      }
    ]
  };

  const chartComponent = () => {
    switch (typeGraphe) {
      case 'pie':
        return (
          <div className="d-flex flex-center me-5 pt-2">
            <Pie data={pieChartData} options={{ maintainAspectRatio: false }} />
          </div>
        );

      case 'donught':
        return (
          <div className="d-flex flex-center me-5 pt-2">
            <Doughnut data={donughtChartData} options={{ maintainAspectRatio: false }} />
          </div>
        );

      case 'bar':
        return (
          <div className="d-flex flex-center me-5 pt-2">
            <Bar
              data={{
                labels: apiData.data ? apiData.data.labels : [],
                datasets: [
                  {
                    label: nomGraphe,
                    data: apiData.data ? apiData.data.data : [],
                    backgroundColor: dynamicColors()
                  }
                ]
              }}
              options={{ maintainAspectRatio: false }}
            />
          </div>
        );

      case 'nombre':
        return (
          <div className="d-flex flex-center me-5 pt-2">
            <NumberCard label={apiData.data ? apiData.data.labels : ''} number={apiData.data ? apiData.data.data : ''} />
          </div>
        );

      case 'liste':
        return (
          <div className="d-flex flex-center me-5 pt-2">
            <List items={apiData.data ? apiData.data.listeDernierProducteur : []} itemsAttributs={["nomPrenom", "typeProd", "anneeEntree"]} />
          </div>
        );

      default:
        return <div>Contenu par défaut</div>;
    }
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}><h2>{nomGraphe}</h2></ModalHeader>
        <ModalBody>
          <div style={{ minWidth: "100%", minHeight: "100%" }}>
            {apiData.data ? chartComponent() : "Loading..."}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default PopUp;

import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BACK_API_URL } from '../../constant.js';
function Aside(){
	const id = localStorage.getItem('id'); 
    const idProfil = localStorage.getItem('idProfil'); 
    const currentToken = localStorage.getItem('token');
	const [producteurData, setProducteursData]=useState([]);
	const [loading,setLoading] = useState(false)

 const synchronisation = async () => {
        try {
          
      
          // Vérifier si le token actuel est expiré
          if (isTokenExpired(currentToken)) {
            // Si expiré, tenter de rafraîchir le token
            await refreshAccessToken();
          }
		  setLoading(true)
          // Maintenant, vous pouvez effectuer votre requête pour obtenir la liste des producteurs
          const url = `https://test.coo.tg/api/traite/liste/mes/new/producteurs/${id}/${idProfil}/0/0`;
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          });
		  
          if (!response.ok) {
			setLoading(false)
            throw new Error(`Erreur HTTP! Statut : ${response.status}`);
          }
		  
          const data = await response.json();
          const abonne= localStorage.getItem('abonne');
          

          console.log(data);
          setProducteursData(data);
          const requestBody = {
            data: producteurData.data,
            abonne: abonne
          };
          fetch(`${BACK_API_URL}/synchronisation/`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestBody),
        })
		.then(response => response.json())
		.then(data => {
			console.log('Réponse de la synchronisation:', data);
			setLoading(false)
		})
		.catch(error => console.error('Erreur lors de la synchronisation:', error));
        } catch (error) {
          console.error(error.message);
          // Gérez l'erreur ici (par exemple, affichez un message à l'utilisateur)
        }
      };

    const isTokenExpired = (token) => {
    if (!token) {
        // Aucun token fourni, donc considéré comme expiré
        return true;
    }

    try {
        const decodedToken = jwtDecode(token);

        // Vérifiez si la date d'expiration (exp) est dans le passé
        const isExpired = decodedToken.exp < Date.now() / 1000;

        return isExpired;
    } catch (error) {
        // Erreur de décodage du token, considéré comme expiré par sécurité
        return true;
    }
    };  
	const refreshAccessToken = async () => {
	try {
		const refreshToken = localStorage.getItem('refreshToken');
		const response = await fetch('https://test.coo.tg/token/refresh', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ refresh_token: refreshToken }),
		});
	
		if (!response.ok) {
		throw new Error(`Erreur lors du rafraîchissement du token. Statut : ${response.status}`);
		}
	
		const newTokenData = await response.json();
		const newToken = newTokenData.token;
	
		// Mettez à jour le nouveau token dans localStorage
		localStorage.setItem('token', newToken);
	
		console.log('Token rafraîchi avec succès :', newToken);
	} catch (error) {
		console.error(error.message);
		// Gérez l'erreur ici (par exemple, déconnectez l'utilisateur)
	}
	};
	//<div id="kt_app_sidebar_nav_wrapper" className="d-flex flex-column px-8 px-lg-10 hover-scroll-y" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="{default: false, lg: '#kt_app_header'}" data-kt-scroll-wrappers="#kt_app_sidebar, #kt_app_sidebar_wrapper" data-kt-scroll-offset="{default: '10px', lg: '40px'}">
	
    return(
        <div id="kt_app_sidebar" className="app-sidebar flex-column" style={{ paddingTop: '10px' }} data-kt-drawer="true" data-kt-drawer-name="app-sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="275px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_app_sidebar_toggle">
			{/*begin::Sidebar nav*/}
			<div className="app-sidebar-wrapper py-8 py-lg-10" id="kt_app_sidebar_wrapper" style={{maxHeight: 'calc(90vh - 100px)' }}>
				{/*begin::Nav wrapper*/}
				<div id="kt_app_sidebar_nav_wrapper" className="d-flex flex-column px-8 px-lg-10" style={{ overflowY: 'auto', maxHeight: 'calc(90vh - 200px)' }}>

				<div className="d-flex mb-8 mb-lg-10"> 
						<div className="min-w-100px w-100">
							{loading ?
								<div className='form-control h-40px w-40px text-center border-hover mx-1 my-2'>
									<i className="fas fa-spinner fa-spin text-primary fs-3"></i>
								</div>
								:
								<a href="#" onClick={synchronisation} 
								className="btn btn-sm btn-success d-flex flex-center px-4 py-3" 
								
								data-bs-target="#kt_modal_invite_friends" >
									<i className="ki-outline ki-plus-square fs-2"></i>
									<span>synchronisation</span>
								</a>}
								{/* <a href="#" className="btn btn-sm btn-dark ms-3 px-4 py-3" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target">Create 
								<span className="d-none d-sm-inline">Target</span></a> */}
						</div>
						{/*begin::Stat
						<div className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6">

							<span className="fs-6 text-gray-500 fw-bold">Budget</span>
							
							<div className="fs-2 fw-bold text-success">$14,350</div>
							
						</div>
						
						<div className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4">
							
							<span className="fs-6 text-gray-500 fw-bold">Spent</span>
							
							<div className="fs-2 fw-bold text-danger">$8,029</div>
							
						</div>
						begin::Links*/}
					</div>
					{/*begin::Progress*/}
					<div className="d-flex align-items-center flex-column w-100 mb-8 mb-lg-10">
						<div className="d-flex justify-content-between fw-bolder fs-6 text-gray-800 w-100 mt-auto mb-3">
							<span>Your Goal</span>
						</div>
						<div className="w-100 bg-light-info rounded mb-2" style={{height: "24px"}}>
							<div className="bg-info rounded" role="progressbar" style={{height: "24px", width: "37%"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
						</div>
						<div className="fw-semibold fs-7 text-primary w-100 mt-auto">
							<span>reached 37% of your target</span>
						</div>
					</div>
					{/*end::Progress*/}
					
					
					
					
					<div className="mb-0">
						{/*begin::Title*/}
						<h3 className="text-gray-800 fw-bold mb-8">Services</h3>
						{/*end::Title*/}
						{/*begin::Row*/}
						<div className="row g-5" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button]">
							{/*begin::Col*/}
							<div className="col-6">
								{/*begin::Link*/}
								<Link to="/pilotage" className="btn btn-icon btn-outline btn-bg-light btn-active-light-primary btn-flex flex-column flex-center w-100px h-100px border-gray-200" data-kt-button="true">
									{/*begin::Icon*/}
									<span className="mb-2">
										<i className="ki-outline ki-calendar fs-1"></i>
									</span>
									{/*end::Icon*/}
									{/*begin::Label*/}
									<span className="fs-7 fw-bold">Pilotage</span>
									{/*end::Label*/}
								</Link>
								{/*end::Link*/}
							</div>
							{/*end::Col*/}
							{/*begin::Col*/}
							<div className="col-6">
								{/*begin::Link*/}
								<a href="apps/support-center/licenses.html" className="btn btn-icon btn-outline btn-bg-light btn-active-light-primary btn-flex flex-column flex-center w-100px h-100px border-gray-200" data-kt-button="true">
									{/*begin::Icon*/}
									<span className="mb-2">
										<i className="ki-outline ki-tablet-book fs-1"></i>
									</span>
									{/*end::Icon*/}
									{/*begin::Label*/}
									<span className="fs-7 fw-bold">Insurance</span>
									{/*end::Label*/}
								</a>
								{/*end::Link*/}
							</div>
							{/*end::Col*/}
							
						</div>
						{/*end::Row*/}
					</div>
					{/*end::Links*/}
				</div>
				{/*end::Nav wrapper*/}
			</div>
			{/*end::Sidebar nav*/}
		</div>
    )

}
export default Aside
// ... (your imports and other code)
import { useState, useEffect } from 'react';
import { BACK_API_URL } from '../../constant.js';
function Filtre({onFilterChange}) {
    
    const [filtres, setFiltres] = useState([]);

  
    useEffect(() => {
        const fetchData = async () => {
        try {
          const response = await fetch(`${BACK_API_URL}/filter/`);
  
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const data = await response.json();
          setFiltres(data);
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, [BACK_API_URL]);
  
    return (
      <div className="container" style={{ marginTop: '-15' }}>
        <div className="info-recherche text-end"  >
          <form className="row">
              <div className="col-md-3">
                
              </div>
              {/* Zone Selector */}
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="zoneSelect">Zone</label>
                  <select className="form-control" id="zoneSelect" name="zoneSelect" onChange={(e) => onFilterChange('zone', e.target.value)}>
                    <option value="0">choisir zone</option>
                    {filtres.zones && Object.entries(filtres.zones).map(([zoneId, zoneLabel]) => (
                      <option key={zoneId} value={zoneId}>{zoneLabel}</option>
                    ))}
                  </select>
                </div>
              </div>
          
              {/* Cooperative Selector */}
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="cooperativeSelect">Coopérative</label>
                  <select className="form-control" id="cooperativeSelect" name="cooperativeSelect" onChange={(e) => onFilterChange('coop', e.target.value)}>
                    <option value="0">choisir Coopérative</option>
                    {filtres.cooperatives && Object.entries(filtres.cooperatives).map(([coopId, coopLabel]) => (
                      <option key={coopId} value={coopId}>{coopLabel}</option>
                    ))}
                  </select>
                </div>
              </div>
          
              {/* Filiere Selector */}
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="filiereSelect">Filière</label>
                  <select className="form-control" id="filiereSelect" name="filiereSelect" onChange={(e) => onFilterChange('filiere', e.target.value)}>
                    <option value="0">choisir filière</option>
                    {filtres.filiere && Object.entries(filtres.filiere).map(([filiereId, filiereLabel]) => (
                      <option key={filiereId} value={filiereId}>{filiereLabel}</option>
                    ))}
                  </select>
                </div>
              </div>
            </form>


        </div>
      
      
 
    </div>
    
    );
  }
  
  export default Filtre;
  
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormAddRapport from './formAddRapport';
import { BACK_API_URL } from '../../constant';
function Rapport(){
  const [rapports,setRapports] = useState([]);
  const [openRapportForm,setOpenRapportFrom]= useState(false)
  const handleOnClick = () => {
    setOpenRapportFrom(!openRapportForm)
  }

  useEffect(() => {
    fetch(`${BACK_API_URL}/rapport/`)
    .then(response => response.json())
    .then((data)=>{setRapports(data)})
    .catch(error => console.error(error));
  },[openRapportForm])

    return(
      <>
      {/*begin::Card*/}
      <div className="card card-flush">
        {/*begin::Card header*/}
        <div className="card-header pt-8">
          <div className="card-title">
            {/*begin::Search*/}
            <div className="d-flex align-items-center position-relative my-1">
              <i className="ki-outline ki-magnifier fs-1 position-absolute ms-6" />
              <input
                type="text"
                data-kt-filemanager-table-filter="search"
                className="form-control form-control-solid w-250px ps-15"
                placeholder="Recherche rapport"
              />
            </div>
            {/*end::Search*/}
          </div>
          {/*begin::Card toolbar*/}
          <div className="card-toolbar">
            {/*begin::Toolbar*/}
            <div
              className="d-flex justify-content-end"
              data-kt-filemanager-table-toolbar="base"
            >
              {/*begin::Export*/}
              <button
                type="button"
                className="btn btn-flex btn-light-primary me-3"
                id="kt_file_manager_new_folder"
                onClick={handleOnClick}
              >
                <i className="ki-outline ki-add-folder fs-2" />
                Nouveau rapport
              </button>
              {/*end::Export*/}
              {/*begin::Add customer
              <button
                type="button"
                className="btn btn-flex btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_upload"
              >
                <i className="ki-outline ki-folder-up fs-2" />
                Upload Files
              </button>
              {/*end::Add customer*/}
            </div>
            {/*end::Toolbar*/}
            
          </div>
          {/*end::Card toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body">
          {/*begin::Table header*/}
          <div className="d-flex flex-stack">
            
           
          </div>
          {/*end::Table header*/}
          {/*begin::Table*/}
          <table
            id="kt_file_manager_list"
            data-kt-filemanager-table="folders"
            className="table align-middle table-row-dashed fs-6 gy-5"
          >
            <thead>
              <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                <th className="w-10px pe-2">
                  <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      data-kt-check="true"
                      data-kt-check-target="#kt_file_manager_list .form-check-input"
                      defaultValue={1}
                    />
                  </div>
                </th>
                <th className="min-w-250px">Nom</th>
                <th className="min-w-10px">Description</th>
                <th className="min-w-125px">Date</th>
                <th className="w-125px" />
              </tr>
            </thead>
            <tbody className="fw-semibold text-gray-600">
            {rapports.map((rapport,index)=>(
              <tr key={index}>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue={1}
                    />
                  </div>
                </td>
                <td data-order="account">
                  <div className="d-flex align-items-center">
                    <span className="icon-wrapper">
                      <i className="ki-outline ki-folder fs-2x text-primary me-4" />
                    </span>
                    <Link to={`/rapport/${rapport.id}`}>
                      <a
                        className="text-gray-800 text-hover-primary"
                      >
                        {rapport.nom}
                      </a>
                    </Link>
                  </div>
                </td>
                <td>-</td>
                <td>-</td>
                <td
                  className="text-end"
                  data-kt-filemanager-table="action_dropdown"
                >
                  <div className="d-flex justify-content-end">
                    {/*begin::Share link*/}
                    <div className="ms-2" data-kt-filemanger-table="copy_link">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-fasten fs-5 m-0" />
                      </button>
                      {/*begin::Menu*/}
                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px"
                        data-kt-menu="true"
                      >
                        {/*begin::Card*/}
                        <div className="card card-flush">
                          <div className="card-body p-5">
                            {/*begin::Loader*/}
                            <div
                              className="d-flex"
                              data-kt-filemanger-table="copy_link_generator"
                            >
                              {/*begin::Spinner*/}
                              <div className="me-5" data-kt-indicator="on">
                                <span className="indicator-progress">
                                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                </span>
                              </div>
                              {/*end::Spinner*/}
                              {/*begin::Label*/}
                              <div className="fs-6 text-gray-900">
                                Generating Share Link...
                              </div>
                              {/*end::Label*/}
                            </div>
                            {/*end::Loader*/}
                            {/*begin::Link*/}
                            <div
                              className="d-flex flex-column text-start d-none"
                              data-kt-filemanger-table="copy_link_result"
                            >
                              <div className="d-flex mb-3">
                                <i className="ki-outline ki-check fs-2 text-success me-3" />
                                <div className="fs-6 text-gray-900">
                                  Share Link Generated
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                defaultValue="https://path/to/file/or/folder/"
                              />
                              <div className="text-muted fw-normal mt-2 fs-8 px-3">
                                Read only.
                                <a
                                  href="apps/file-manager/settings/.html"
                                  className="ms-2"
                                >
                                  Change permissions
                                </a>
                              </div>
                            </div>
                            {/*end::Link*/}
                          </div>
                        </div>
                        {/*end::Card*/}
                      </div>
                      {/*end::Menu*/}
                      {/*end::Share link*/}
                    </div>
                    {/*begin::More*/}
                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <i className="ki-outline ki-dots-square fs-5 m-0" />
                      </button>
                      {/*begin::Menu*/}
                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                        data-kt-menu="true"
                      >
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="apps/file-manager/files.html"
                            className="menu-link px-3"
                          >
                            View
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table="rename"
                          >
                            Rename
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            Download Folder
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-kt-filemanager-table-filter="move_row"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_move_to_folder"
                          >
                            Move to folder
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link text-danger px-3"
                            data-kt-filemanager-table-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                        {/*end::Menu item*/}
                      </div>
                      {/*end::Menu*/}
                      {/*end::More*/}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
          {/*end::Table*/}
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Card*/}
      {openRapportForm ? <FormAddRapport modal={openRapportForm} toggle={handleOnClick}/>:null}
    </>
    
);
}
export default Rapport
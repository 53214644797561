import React, { useState, useEffect } from 'react';
import Accordion  from 'react-bootstrap/Accordion';
import PopUp from '../fragments/popUp';
import { BACK_API_URL } from '../../constant.js';
function Indicateur(){
  const [categories, setCategories] = useState([]);
  const [open,setOpen] = useState(false);
  const [selectedIndicateur,setSelectedIndicateur] = useState(null);
  const [openIndicateurModal,setOpenIndicateurModal] = useState(false);
  const handleIndicateurModal = () => {setOpenIndicateurModal(!openIndicateurModal)}
  const apiUrl = `${BACK_API_URL}/group-indicateurs/`; 
  const handlePopupClick = (indicateur) => {
    setSelectedIndicateur(indicateur);
    handleIndicateurModal();
    console.log("selectedIndicateur",indicateur);
  };

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        setCategories(data);
        console.log(data); // Log the data for debugging purposes
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);



    return (
      
      <div >
        {categories.map((category,index) => (
          <Accordion  defaultActiveKey={index}>
            <Accordion.Item eventKey={index}>
              <Accordion.Header>
                {category.nom}
              </Accordion.Header>
              <Accordion.Body>
                    {category.indicateurs.map((indicateur,index) => (
                      <p >
                        <button type="button" class="btn btn-link" onClick={() => handlePopupClick(indicateur)}>
                          {indicateur.nom} 
                        </button>
                      </p> 
                    ))}
              </Accordion.Body>
            </Accordion.Item>          
          </Accordion>
        ))}
      {openIndicateurModal && selectedIndicateur ? <PopUp nomGraphe={selectedIndicateur.nom?selectedIndicateur.nom:""} typeGraphe={selectedIndicateur.typeGraphe ?selectedIndicateur.typeGraphe.nom:""  } endpoint={selectedIndicateur.endpoint?selectedIndicateur.endpoint:""} typeAction={selectedIndicateur.typeAction?selectedIndicateur.typeAction:""}  modal={openIndicateurModal} toggle={handleIndicateurModal} /> : null}
      </div>
    );
  }

export default Indicateur
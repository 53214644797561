import { Navigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';


const ProtectedRoute = ({ element: Component, ...rest }) => {
    const token = localStorage.getItem('token');
  
    if (!token) {
      // Si le token n'existe pas, rediriger vers la page de connexion
      return <Navigate to="/login" replace />;
    }
  
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
  
      if (decodedToken.exp < currentTime) {
        // Si le token est expiré, rediriger vers la page de connexion
        return <Navigate to="/login" replace />;
      }
    } catch (error) {
      // Si une erreur se produit lors du décodage du token, rediriger vers la page de connexion
      return <Navigate to="/login" replace />;
    }
  
    return <Component {...rest} />;
  };
  
  export default ProtectedRoute;
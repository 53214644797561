

import { Route, BrowserRouter as Router, Routes ,Navigate} from 'react-router-dom';
import Home from '../home/home.jsx';
import Login from '../Auth/login.jsx';
import Pilotage from '../pilotage/pilotage.jsx';
import RapportGraphes from '../pilotage/rapportGraphes.jsx';
import ProtectedRoute from '../../context/ProtectedRoute.jsx';
function App() {
  return (
    <Router basename='/'>
      <Routes>
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/' element={<Navigate to="/login" replace />} />
        <Route path="/home" element={<ProtectedRoute element={Home} />} />
        <Route path="/pilotage" element={<ProtectedRoute element={Pilotage} />} />
        <Route path="/rapport/:id" element={<ProtectedRoute element={RapportGraphes} />} />
      </Routes>
    </Router>
  );
}

export default App;


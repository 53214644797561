export const GSMILES_API_URL = "https://test.coo.tg"
export const BACK_API_URL = "http://gsmilesapp.com:8000/api"
//export const BACK_API_URL = "http://127.0.0.1:8000/api"

export const BODY = {
    "zoneId": "0",
    "groupementId": "0",
    "inspectionId": "34",
    "produitId": "0",
    "abonneId": "94",
    "agentId": "0",
    "campagneId": "0"
  }